<div class="root" [class.show]="show" [class.hide]="!show">
  <button type="button" class="btn btn-primary open-btn" (click)="toggleShow()">
    <!-- <i [class]="show ? 'bi-caret-right' : 'bi-caret-left'"></i> -->
    <i class="bi-list"></i>
  </button>

  <div class="content">
    <div class="static-items borders only-borders-bottom">
      <ng-container
        *ngFor="let staticPart of staticCourseParts; let index = index"
      >
        <ng-container
          *ngTemplateOutlet="
            staticCoursePart;
            context: { part: staticPart, index: index }
          "
        ></ng-container>
      </ng-container>
    </div>

    <app-course-progress
      [progress]="progress"
      [coursePointsProgress]="coursePointsData.coursePoints"
    ></app-course-progress>

    <div class="marginTop">
      <div
        *ngFor="let coursePart of course?.parts; let index = index"
        class="course-part"
      >
        <ng-container
          *ngTemplateOutlet="
            coursePartTitle;
            context: {
              finished: partsFinished[index],
              index: index,
              coursePart: coursePart
            }
          "
        ></ng-container>

        <div #collapse="ngbCollapse" [(ngbCollapse)]="collapsed[index]">
          <ul>
            <li
              *ngFor="
                let subPart of coursePart.activities;
                let subIndex = index
              "
              [class.active]="subPart.id === curActivityId"
              [class.finished]="subPart.finished"
              [class.complete]="
                !subPart.finished && subPart.grade && subPart.grade?.grade === 1
              "
              [class.failed]="
                !subPart.finished && subPart.grade && subPart.grade?.grade === 0
              "
              [class.unfinished]="!subPart.finished && !subPart.grade"
              (click)="subPart.unlocked && selectActivity(subPart.id)"
            >
              <span class="subPart">
                <i class="marker"
                  [class]="
                    !subPart.unlocked
                      ? 'bi-lock-fill'
                      : subPart.finished
                      ? 'bi-check-circle-fill'
                      : !subPart.finished &&
                        subPart.grade &&
                        subPart.grade?.grade < 2
                      ? 'bi-circle-fill'
                      : 'bi-circle'
                  "
                ></i>
                <span class="title">
                  {{ subPart.title }}
                </span>

                <i
                  [class]="'bi-'+activityTypeIconMappings[subPart.type].icon"
                  [ngbTooltip]="activityTypeIconMappings[subPart.type].name"
                ></i>
                <i
                  [class]="subPart.deadline !== null ? 'bi-alarm' : ''"
                  ngbTooltip="Deadline"
                ></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #staticCoursePart let-index="index" let-part="part">
  <h6
    [class.active]="part.id === curActivityId"
    (click)="selectActivity(part.id)"
  >
    <i [class]="'bi-'+part.iconName"></i>
    {{ part.title }}
  </h6>
</ng-template>

<ng-template
  #coursePartTitle
  let-finished="finished"
  let-index="index"
  let-coursePart="coursePart"
>
  <h6 (click)="toggleCoursePart(index)">
    <span
      [class.active]="index === activeCoursePartIndex"
      [class.finished]="finished"
    >
      <i
        [class]="collapsed[index] ? 'bi-caret-right-fill' : 'bi-caret-down-fill'"
      ></i>
      <span>
        {{ coursePart.title }}
      </span>
    </span>

    <span>
      <span class="badge badge-light border border-secondary" *ngIf="!finished">
        {{
          getTrueCount(coursePart.activities) +
            '/' +
            coursePart.activities.length
        }}
        <i
          class="award bi-award"
          [class.accomplished]="finished"
          style="font-size: 13px"
        ></i>
      </span>

      <span
        class="badgeDone badge badge-light border border-success"
        *ngIf="finished"
      >
        {{
          getTrueCount(coursePart.activities) +
            '/' +
            coursePart.activities.length
        }}
        <i
          class="award bi-award-fill"
          [class.accomplished]="finished"
          style="font-size: 13px"
        ></i>
      </span>
    </span>
  </h6>
</ng-template>
