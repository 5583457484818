<div class="root">
  <h5>
    <span class="long">{{
      title?.shortTitle
        ? title?.shortTitle + ' - ' + title?.defaultTitle
        : title?.defaultTitle
    }}</span>
    <span class="short">{{ title.shortTitle }}</span>
  </h5>

  <div class="links-container">
    <ng-container *ngFor="let link of customLinks">
      <ng-container
        *ngTemplateOutlet="
          linkItem;
          context: {
            link: link,
            activated: link.forceActivated
              ? true
              : activatedMainRoute === link.link
          }
        "
      ></ng-container>
    </ng-container>

    <ng-container *ngFor="let link of defaultLinks">
      <ng-container
        *ngTemplateOutlet="
          linkItem;
          context: {
            link: link,
            activated: activatedMainRoute === link.link || link.forceActivated
          }
        "
      ></ng-container>
    </ng-container>
  </div>

  <button
    type="button"
    class="btn btn-outline-primary"
    (click)="handleSignOut()"
  >
    Logga ut
  </button>
</div>

<ng-template #linkItem let-link="link" let-activated="activated">
  <div
    class="item"
    [class.active]="activated"
    (click)="link.link ? navigate(link.link) : link.onClick()"
  >
    <i *ngIf="link.icon" [class]="'bi-'+link.icon" [ngbTooltip]="link.name"></i>

    <span *ngIf="link.text">
      {{ link.text }}
    </span>

    <div class="notifications-label" [hidden]="!link.notificationsAmount">
      {{ link.notificationsAmount }}
    </div>
  </div>
</ng-template>
