<table mdbTable>
  <thead>
    <tr>
      <th *ngFor="let head of headers">
        {{ head }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr mdbTableCol *ngFor="let activityFileSubmission of activityFileSubmissions">
      <td>
        <i>Inlämnad</i>
        <br />
        {{ activityFileSubmission.date | date: 'dd-MM-yyyy HH:mm' }}
        av {{ activityFileSubmission.submittedBy }}
      </td>
      <ng-container *ngIf="activityFileSubmission.filename">
        <td [innerHTML]="activityFileSubmission.comment"></td>
        <td>
          <app-submission-file [pdfInfo]="activityFileSubmission"></app-submission-file>
        </td>
      </ng-container>
    </tr>
    <ng-container *ngIf="assignmentResult">
      <tr>
        <td>
          <i>Rättad</i>
          <br />
          {{ assignmentResult.date | date: 'dd-MM-yyyy HH:mm' }} av
          <a [href]="
              'https://liu.se/medarbetare/' + assignmentResult?.admin.liuId
            " target="_blank" [innerHTML]="'\<br>' + assignmentResult?.admin.liuId">
          </a>
        </td>
        <td [innerHTML]="assignmentResult.feedback"></td>
        <td>
          <div class="grade-container">
            <app-result-label [grade]="assignmentResult.grade" [marked]="true"></app-result-label>
          </div>
        </td>
      </tr>
    </ng-container>
  </tbody>
</table>