import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface FileSubmission {
  activityId: number;
  filename: string;
  id: number;
  date: Date;
  submittedBy: string;
}

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  constructor(private http: HttpClient) { }

  upload(file: File, activityId: number) {
    var formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('activityId', activityId.toString());

    return this.http.post<FileSubmission>(
      `${environment.api}/course/activity/upload`,
      formData
    );
  }

  public getSubmissionFiles(activityId: number) {
    return this.http.get<FileSubmission[]>(
      `${environment.api}/course/activity/upload/${activityId}`
    );
  }

  public deleteSubmissionFile(fileId: number) {
    return this.http.delete<FileSubmission>(
      `${environment.api}/course/activity/upload/${fileId}`
    );
  }

  public getFile(activityId: number, filename: string) {
    return this.http.get(
      `${environment.api}/course/activity/upload/${activityId}/${filename}`,
      { responseType: 'blob' }
    );
  }

  public downloadFile(activityId: number, filename: string) {
    this.getFile(activityId, filename).subscribe((blob) => {
      var downloadLink = document.createElement('a');
      downloadLink.href = window.URL.createObjectURL(
        new Blob([blob], {
          type: blob.type,
        })
      );
      if (filename) downloadLink.setAttribute('download', filename);
      downloadLink.click();
    });
  }
}
