import { Component, OnInit, Input } from '@angular/core';
import { CourseCalendar } from 'src/models/course-calendar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Course } from 'src/models/course';
import { CourseDataService } from 'src/app/services/course-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-course-calendar',
  templateUrl: './course-calendar.component.html',
  styleUrls: ['./course-calendar.component.scss'],
})
export class CourseCalendarComponent implements OnInit {
  urlSafe: SafeResourceUrl;

  calendarItem: CourseCalendar = {
    calendarUrl:
      'https://cloud.timeedit.net/liu/web/schema/ri665XQn065Z56Qm17045ZZ6y1Y730QQ0Y43Y8gQ30765.html',
  };

  constructor(
    private sanitizer: DomSanitizer,
    private courseDataService: CourseDataService
  ) {}

  ngOnInit() {
    this.courseDataService.$course
      .pipe(untilDestroyed(this))
      .subscribe((course) => this.onCourseChange(course));
  }

  onCourseChange(course: Course) {
    let url;
    if (course?.calendarUrl) {
      url = course?.calendarUrl;
    } else {
      url = '';
    }
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
