import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  FileSubmission,
  FileUploadService,
} from 'src/app/services/file-upload.service';

@Component({
  selector: 'app-submission-file',
  templateUrl: './submission-file.component.html',
  styleUrls: ['./submission-file.component.scss'],
})
export class SubmissionFileComponent implements OnInit {
  @Input() pdfInfo: FileSubmission;

  opened: boolean = false;
  pdf: Blob;

  private objectUrl: string;
  safeUrl: SafeResourceUrl;

  constructor(
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.fileUploadService
      .getFile(this.pdfInfo.activityId, this.pdfInfo.filename)
      .subscribe((res) => {
        this.pdf = res;
        this.objectUrl = window.URL.createObjectURL(
          new Blob([this.pdf], {
            type: this.pdf.type,
          })
        );
        this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
          this.objectUrl
        );
      });
  }
}
