<svg
  *ngIf="grade == 3"
  xmlns="http://www.w3.org/2000/svg"
  color="#cd7f32"
  fill="currentColor"
  class="badge_1"
  viewBox="0 0 17 17"
>
  <path
    d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
  />
</svg>

<svg
  *ngIf="grade == 4"
  xmlns="http://www.w3.org/2000/svg"
  color="#c0c0c0"
  fill="currentColor"
  class="badge_1"
  viewBox="0 0 17 17"
>
  <path
    d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
  />
</svg>

<svg
  *ngIf="grade == 5"
  xmlns="http://www.w3.org/2000/svg"
  color="#FFD700"
  fill="currentColor"
  class="badge_1"
  viewBox="0 0 17 17"
>
  <path
    d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
  />
</svg>

<svg
  *ngIf="progress == 0"
  xmlns="http://www.w3.org/2000/svg"
  color="#cd7f32"
  fill="currentColor"
  class="badge_1"
  viewBox="0 0 17 17"
>
  <path
    d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z"
  />
</svg>
