<ng-container *ngIf="false"
  ><p>
    Kursen består av 3 områden: Personlig ledning, Kommunikation och
    Ingenjörsskap. Varje område består i sin tur av aktiviteter som måste
    genomföras och godkännas för att få godkänt i kursen.
  </p>

  <p>
    I kursen finns det tre typer av poäng:

    <img src="https://www.ida.liu.se/~TDDI83/exam/poang.png" width="80%" />
    <br />
    För att bli godkänd på kursen så behöver man ha 56 Kurspoäng och 26
    Ordningspoäng. I kursen kommer det att tilldelas en utmärkelsebelöning som
    visar på att studenten har geomfört aktiviteterna på dels högre nivå och
    dels frivilligt. Utmärkelsebelöningen kommer att vara en speciell mugg.
    Nedan visas en sammanställning på krav på poäng för godkänd på kursen och
    för utmärkelsebelöningen
  </p>
</ng-container>
