import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CoursePageService } from 'src/app/services/course-page.service';
import { UserMenuService } from 'src/app/services/user-menu.service';
import { NotificationType } from 'src/enums/notification-type.enum';
import { GradeNotification } from 'src/models/notification/grade-notification';
import { NewsNotification } from 'src/models/notification/news-notification';
import { ActivityReminderNotification } from 'src/models/notification/activity-reminder-notification';
import { Notification } from 'src/models/notification/notification';
import { NotificationsDataService } from 'src/app/services/notifications-data.service';
import { GroupReminderNotification } from 'src/models/notification/group-reminder-notification';
import { ActivityResultNotification } from 'src/models/notification/activity-result-notification';
import { CourseResultNotification } from 'src/models/notification/course-result-notification';

class NotificationUrl {
  path: string;
  parameters?: object;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  readonly gradeNotificationType = NotificationType.grade;
  readonly newsNotificationType = NotificationType.courseNews;
  readonly activityReminderType = NotificationType.activityReminder;
  readonly groupReminderType = NotificationType.groupReminder;
  readonly activityResultNotificationType = NotificationType.activityResult;
  readonly courseResultNotificationType = NotificationType.courseResult;

  @Input() set notification(notification: Notification) {
    this.curNotificationBase = notification;

    switch (notification?.type) {
      case NotificationType.grade:
        this.gradeNotification = notification as GradeNotification;
        this.notificationUrl = this.getGradeUrl();
        break;
      case NotificationType.courseNews:
        this.newsNotification = notification as NewsNotification;
        this.notificationUrl = this.getNewsPath();
        break;
      case NotificationType.activityReminder:
        this.activityReminderNotification =
          notification as ActivityReminderNotification;
        this.notificationUrl = this.getActivityPath();
        break;
      case NotificationType.groupReminder:
        this.groupReminderNotification =
          notification as GroupReminderNotification;
        this.notificationUrl = this.getGroupPath();
        break;
      case NotificationType.activityResult:
        this.activityResultNotification =
          notification as ActivityResultNotification;
        this.notificationUrl = this.getActivityResultPath();
        break;
      case NotificationType.courseResult:
        this.courseResultNotification =
          notification as CourseResultNotification;
        this.notificationUrl = this.getCourseResultPath();
        break;
      default:
        this.notificationUrl = undefined;
    }
  }

  curNotificationBase: Notification;
  gradeNotification: GradeNotification;
  newsNotification: NewsNotification;
  activityReminderNotification: ActivityReminderNotification;
  groupReminderNotification: GroupReminderNotification;
  activityResultNotification: ActivityResultNotification;
  courseResultNotification: CourseResultNotification;

  notificationUrl: NotificationUrl;

  constructor(
    private userMenuService: UserMenuService,
    private router: Router,
    private coursePageService: CoursePageService,
    private notificationDataService: NotificationsDataService
  ) {}

  ngOnInit(): void {}

  select() {
    this.userMenuService.setNotificationsVisible(false);
    this.router.navigate([this.notificationUrl.path], {
      //relativeTo: this.route,
      queryParams: this.notificationUrl.parameters,
      //queryParamsHandling: 'merge'
    });

    if (this.gradeNotification) {
      this.coursePageService.showResultForCurActivity();
    }

    if (this.activityReminderNotification) {
      this.notificationDataService
        .markActivityDeadlineRead(this.activityReminderNotification.activityId)
        .subscribe();
    }

    if (this.curNotificationBase.type == this.activityResultNotificationType) {
      this.notificationDataService
        .markActivityResultRead(this.activityResultNotification.id)
        .subscribe();
    } else if (
      this.curNotificationBase.type == this.courseResultNotificationType
    ) {
      this.notificationDataService
        .markCourseResultRead(this.courseResultNotification.id)
        .subscribe();
    }
  }

  private getGradeUrl() {
    return {
      path: `/course/${this.gradeNotification.courseId}`,
      parameters: { activityId: this.gradeNotification.activityId },
    };
  }

  private getNewsPath() {
    return {
      path: `/course/${this.newsNotification.courseId}`,
      parameters: { activityId: CoursePageService.STATIC_COURSE_PARTS[0].id },
    };
  }

  private getActivityPath() {
    return {
      path: `/course/${this.activityReminderNotification.courseId}`,
      parameters: { activityId: this.activityReminderNotification.activityId },
    };
  }

  private getGroupPath() {
    return {
      path: `/course/${this.groupReminderNotification.courseId}`,
      parameters: {
        activityId: CoursePageService.STATIC_COURSE_PARTS[3].id,
        collection: this.groupReminderNotification.collectionId,
      },
    };
  }

  private getActivityResultPath() {
    return {
      path: `/course/${this.activityResultNotification.courseId}?activityId=${this.activityResultNotification.activityId}`,
      parameters: { activityId: this.activityResultNotification.activityId },
    };
  }

  private getCourseResultPath() {
    return {
      path: `/course/${this.courseResultNotification.courseId}`,
      parameters: { activityId: CoursePageService.STATIC_COURSE_PARTS[1].id },
    };
  }
}
