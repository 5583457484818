import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CourseDataService } from 'src/app/services/course-data.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { CourseOverview } from 'src/models/course-overview';

@UntilDestroy()
@Component({
  selector: 'app-course-overviews-list',
  templateUrl: './course-overviews-list.component.html',
  styleUrls: ['./course-overviews-list.component.scss'],
})
export class CourseOverviewsListComponent implements OnInit {
  private static readonly TITLE = { defaultTitle: 'Mina kurser' };

  courseOverviews: CourseOverview[];

  constructor(
    private http: HttpClient,
    private toolbar: ToolbarService,
    private courseService: CourseDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.toolbar.setTitle(CourseOverviewsListComponent.TITLE);

    this.courseService.$courses
      .pipe(untilDestroyed(this))
      .subscribe((overviews) => (this.courseOverviews = overviews));

    this.courseService.getCourseOverviews().subscribe();
  }
}
