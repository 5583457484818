<div class="content">
  <h3 [hidden]="!quizPart">{{ activeIndex + 1 }}. {{ quizPart?.title }}</h3>
  <h5 [innerHTML]="quizPart?.description"></h5>

  <app-quiz-answers-select></app-quiz-answers-select>
  <app-activity-point-overview
    *ngIf="activityPointsData?.criterias?.length != 0"
    [activityCriterias]="activityPointsData.criterias"
    [pointSummary]="activityPointsData.pointSummary"
    [withResult]="false"
  >
  </app-activity-point-overview>
  <div class="results-container" [hidden]="!improvementTexts">
    <h4>
      <span [hidden]="!improvementTexts?.length">
        Bra jobbat, du skulle kunna träna på följande moment:
      </span>

      <span [hidden]="improvementTexts?.length">
        Bra jobbat, du klarade alla moment på första försöket!
      </span>
    </h4>

    <ul>
      <li
        class="result"
        *ngFor="let text of improvementTexts; let last = last"
        [class.borders]="!last"
        [class.only-borders-bottom]="!last"
      >
        <b>{{ text }}</b>
      </li>
    </ul>
  </div>
</div>
