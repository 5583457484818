import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UserMenuService } from 'src/app/services/user-menu.service';

@Component({
  selector: 'app-menu-container',
  templateUrl: './menu-container.component.html',
  styleUrls: ['./menu-container.component.scss'],
})
export class MenuContainerComponent implements OnInit {
  @Input() set visible(visible: boolean) {
    this.setIsVisible(visible);
  }

  @Output() hide = new EventEmitter();

  public static readonly HIDE_TIME = 100; //Ms

  isVisible: Boolean;
  completelyHidden: Boolean;
  completelyHiddenTimeout;

  outsideClickEnabled = false;
  outsideClickDelayTimeout;

  constructor(private eRef: ElementRef) {}

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.outsideClickEnabled) return;

    if (!this.eRef.nativeElement.contains(event.target)) {
      // The user clicked outside of the menu
      this.hide.emit();
    }
  }

  ngOnInit(): void {}

  private setIsVisible(visible: boolean) {
    this.isVisible = visible;

    this.clearCompletelyHiddenTimeout();
    this.clearOutSideClickDelayTimeout();

    if (visible) {
      this.completelyHidden = false;
      this.outsideClickDelayTimeout = setTimeout(() => {
        this.outsideClickEnabled = true;
        this.clearOutSideClickDelayTimeout();
      }, 1);
    } else {
      this.outsideClickEnabled = false;

      this.completelyHiddenTimeout = setTimeout(() => {
        this.completelyHidden = true;
        this.clearCompletelyHiddenTimeout();
      }, MenuContainerComponent.HIDE_TIME);
    }
  }

  private clearCompletelyHiddenTimeout() {
    this.clearTimeout(this.completelyHiddenTimeout);
    this.completelyHiddenTimeout = null;
  }

  private clearOutSideClickDelayTimeout() {
    this.clearTimeout(this.outsideClickDelayTimeout);
    this.outsideClickDelayTimeout = null;
  }

  private clearTimeout(timeout) {
    if (timeout) {
      clearTimeout(timeout);
    }
  }
}
