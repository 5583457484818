import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { Grade } from 'src/enums/grade';

const GRADE_KEYS = [];
_.forEach(Grade, (grade) => {
  if (!isNaN(Number(grade))) {
    return false;
  }

  GRADE_KEYS.push(grade);
});

@Pipe({
  name: 'gradeKey',
  pure: true,
})
export class GradeKeyPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null || value === undefined) {
      return null;
    }

    return _.nth(GRADE_KEYS, value);
  }
}
