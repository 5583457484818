import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { CourseDataService } from 'src/app/services/course-data.service';
import { CoursePageService } from 'src/app/services/course-page.service';
import { NotificationsDataService } from 'src/app/services/notifications-data.service';
import { Course } from 'src/models/course';
import { CourseGrades } from 'src/models/course-grades.ts';
import { CoursePointsData } from 'src/models/course-points';
import { GradeCriteria } from 'src/models/grade-description/grade-criteria';

@UntilDestroy()
@Component({
  selector: 'app-course-results',
  templateUrl: './course-results.component.html',
  styleUrls: ['./course-results.component.scss'],
})
export class CourseResultsComponent implements OnInit {
  @Input() set active(active: boolean) {
    this._active = active;
    this.updateData();
  }

  _active: boolean;
  currentCourse: Course;
  courseGrades: CourseGrades;
  currentPoints: GradeCriteria;
  coursePointsData: CoursePointsData;

  activePart: any;

  activeTab = 0;

  constructor(
    private coursePageService: CoursePageService,
    private courseDataService: CourseDataService,
    private notificationsService: NotificationsDataService
  ) { }

  ngOnInit(): void {
    this.courseDataService.$course
      .pipe(untilDestroyed(this))
      .subscribe((course) => {
        this.currentCourse = course;
        this.getCoursePointsData(course);
        this.updateData();
      });

    this.courseDataService.$results
      .pipe(untilDestroyed(this))
      .subscribe((results) => {
        this.courseGrades = results;
        this.markResultsRead();
      });
  }

  navigateToActivity(activityId: number) {
    this.coursePageService.setActivityId(activityId);
  }

  setActivePart(id: number) {
    this.coursePageService.setCoursePartId(id);
  }
  private getCoursePointsData(course: Course) {
    this.courseDataService.getCoursePointsData(course?.id).subscribe((res) => {
      this.coursePointsData = res;
    });
  }

  private updateData() {
    if (this._active && this.currentCourse) {
      this.courseDataService
        .getCourseResults(this.currentCourse.id)
        .subscribe();
      return;
    }
  }

  private markResultsRead() {
    if (this.courseGrades) {
      const gradingIds = _.chain(this.courseGrades.partsResults)
        .map((partResult) => partResult.activitiesResults)
        .flatten()
        .map((activityGrade) => activityGrade.gradingId)
        .compact()
        .value();

      this.notificationsService.markGradesRead(gradingIds).subscribe();
    }
  }
}
