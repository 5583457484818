<ng-container *ngIf="!DISABLE_LAZY_LOADING">
  <img
    class="placeholder"
    [ngStyle]="{ width: imageWidth, height: imageHeight }"
    *ngIf="loadingMain"
    [src]="loadPreview ? (imageId | imageUrl: true) : ''"
  />

  <img
    class="main"
    [ngStyle]="{ width: imageWidth, height: imageHeight }"
    [hidden]="loadingMain"
    (load)="onLoad()"
    [src]="loadMain ? (imageId | imageUrl) : ''"
    alt="loading"
  />
</ng-container>

<ng-container *ngIf="DISABLE_LAZY_LOADING">
  <img
    class="main"
    [ngStyle]="{ width: imageWidth, height: imageHeight }"
    [src]="imageId | imageUrl"
    alt="loading"
  />
</ng-container>
