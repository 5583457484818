<nav
  ngbNav
  #nav="ngbNav"
  class="nav-tabs responsive-nav equal-widths"
  [(activeId)]="activeTab"
  [destroyOnHide]="false"
>
  <ng-container [ngbNavItem]="0">
    <a ngbNavLink>Översikt</a>
    <ng-template ngbNavContent>
      <ng-container *ngTemplateOutlet="overview"></ng-container>
    </ng-template>
  </ng-container>

  <ng-container
    *ngFor="let part of courseGrades?.partsResults; let partIndex = index"
    [ngbNavItem]="partIndex + 1"
  >
    <a (click)="setActivePart(part.id)" ngbNavLink>{{ part.title }}</a>
    <ng-template ngbNavContent>
      <p [innerHTML]="part.resultDescription"></p>

      <div class="overview-result" *ngIf="part.result | isNumber">
        <p>Resultat:</p>
        <app-result-label
          [grade]="part.result"
          [marked]="true"
        ></app-result-label>
      </div>

      <!-- <div class="overview-result" *ngIf="part.result | isNumber">
        <p>Badges:</p>
        <app-badge-label
          [grade]="part.result"
          [marked]="true"
        ></app-badge-label>
      </div> -->

      <ngb-accordion #acc="ngbAccordion">
        <ng-container
          *ngFor="
            let activity of part.activitiesResults;
            let activityIndex = index
          "
        >
          <ngb-panel [disabled]="false" cardClass="ngb-panel-card">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="activity-panel-header">
                <button class="clickable-area" ngbPanelToggle>
                  <h5 class="activityTitle">
                    {{ activity.activityTitle }}
                  </h5>

                  <app-result-label
                    *ngIf="activity?.result | isNumber"
                    [grade]="activity.result"
                    [marked]="true"
                  ></app-result-label>
                </button>

                <button
                  type="button"
                  class="btn btn-primary nav-button"
                  (click)="navigateToActivity(activity.activityId)"
                >
                  <i class="bi-arrow-right-short"></i>
                </button>
              </div>
            </ng-template>

            <ng-template ngbPanelContent>
              <app-activity-grade-criteria
                [result]="activity.result"
                [comment]="activity.comment"
                [grades]="activity.grades"
                [outlined]="false"
              ></app-activity-grade-criteria>
            </ng-template>
          </ngb-panel>
        </ng-container>
      </ngb-accordion>
      <app-course-part-point-overview
        [partId]="part.id"
        [coursePointsData]="coursePointsData"
      ></app-course-part-point-overview>
    </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav" class="mt-2"></div>

<ng-template #overview>
  {{ courseGrades?.gradeDescription }}

  <table class="overview-table">
    <thead>
      <th>Modul</th>
      <th>Resultat</th>
      <!-- <th>Badges</th> -->
    </thead>
    <tbody>
      <tr *ngFor="let part of courseGrades?.partsResults">
        <td>{{ part.title }}</td>
        <td>
          <app-result-label
            *ngIf="part.result | isNumber"
            [grade]="part.result"
            [marked]="true"
            [grow]="true"
          ></app-result-label>
        </td>

        <!--         <td>
          <app-badge-label [grade]="part.result" [marked]="true">
          </app-badge-label>
        </td> -->
      </tr>

      <tr class="separated" *ngIf="courseGrades?.courseResult">
        <td class="final-result-label">Slutbetyg</td>
        <td>
          <app-result-label
            [grade]="courseGrades?.courseResult"
            [marked]="true"
            [grow]="true"
          ></app-result-label>
        </td>
      </tr>
    </tbody>
  </table>

  <app-course-point-overview
    [coursePointsData]="coursePointsData"
  ></app-course-point-overview>
</ng-template>
