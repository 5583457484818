import { Component, Input, OnInit } from '@angular/core';
import { untilDestroyed } from '@ngneat/until-destroy';

import { CoursePageService } from 'src/app/services/course-page.service';
import { CoursePointsData, Part } from 'src/models/course-points';

@Component({
  selector: 'app-course-part-point-overview',
  templateUrl: './course-part-point-overview.component.html',
  styleUrls: ['./course-part-point-overview.component.scss'],
})
export class CoursePartPointOverviewComponent implements OnInit {
  @Input() coursePointsData: CoursePointsData;
  @Input() partId: number;

  coursePartPointsData: Part;

  constructor(private coursePageService: CoursePageService) {}

  ngOnInit(): void {
    this.coursePageService.$curCoursePartId.subscribe((id) => {
      this.onCoursePartIdChange(id);
    });

    this.coursePartPointsData = this.coursePointsData.parts.find(
      (part) => part.coursePartId === this.partId
    );
  }

  private onCoursePartIdChange(id: number) {
    this.coursePartPointsData = this.coursePointsData.parts.find(
      (part) => part.coursePartId === id
    );
  }
}
