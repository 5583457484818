import { Component, Input, OnInit } from '@angular/core';
import { CoursePointsData } from 'src/models/course-points';

@Component({
  selector: 'app-course-point-overview',
  templateUrl: './course-point-overview.component.html',
  styleUrls: ['./course-point-overview.component.scss'],
})
export class CoursePointOverviewComponent implements OnInit {
  @Input() coursePointsData: CoursePointsData;

  constructor() {}

  ngOnInit(): void {}
}
