<ul
  ngbNav
  #nav="ngbNav"
  [(activeId)]="active"
  (activeIdChange)="onNavIdChange()"
  class="nav-tabs"
>
  <li *ngFor="let collection of courseCollections" [ngbNavItem]="collection.id">
    <a ngbNavLink>{{ collection.name }}</a>
    <ng-template ngbNavContent>
      <ng-container
        *ngTemplateOutlet="groups; context: { collection: collection }"
      ></ng-container>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>

<ng-template #groups let-collection="collection">
  <h4
    *ngIf="collection.signupDeadline !== null"
    style="display: flex; justify-content: center"
  >
    <i class="bi-alarm"></i>
    Deadline för anmälan är
    {{ collection.signupDeadline | date: 'MMM d, y, HH:mm':'GMT+1' }}
  </h4>
  <div class="groups">
    <table
      class="table table-sm table-striped"
      *ngFor="let group of collection.groups"
    >
      <caption>
        <em class="left">
          <span>
            {{ group.name }}
          </span>
        </em>
        <em class="right">
          <span *ngIf="group.assistants.length === 0">Assistant: --</span>
          <span *ngIf="group.assistants.length === 1">Assistant: </span>
          <span *ngIf="group.assistants.length > 1">Assistants: </span>
          <span *ngFor="let assistant of group.assistants; let j = index">
            <a [href]="'mailto:' + assistant.email">{{ assistant.name }}</a>
            <span *ngIf="j < group.assistants.length - 1">, </span>
          </span>
        </em>
      </caption>

      <thead>
        <tr>
          <th class="mail-column">
            <a [href]="group.groupMail" target="_blank">
              <i class="bi-envelope"></i>
              Maila till gruppen
            </a>
          </th>
          <th class="student-column">Student</th>
          <th class="actions-column">Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="let member of group.users"
          [ngClass]="{ 'table-primary': member.liuId === currentUser }"
        >
          <td class="mail-column">
            <a [href]="'mailto:' + member.email" target="_blank">
              <i class="bi-envelope"></i><br />
            </a>
          </td>
          <td class="student-column">
            <ng-container>
              <p
                class="group-member"
                [ngClass]="{
                  accepted:
                    member.liuId === currentUser && member.membershipConfirmed,
                  declined:
                    member.liuId === currentUser && !member.membershipConfirmed
                }"
              >
                {{ member.name }} ({{ member.liuId }})
              </p>
            </ng-container>
          </td>
          <td class="actions-column">
            <ng-container
              *ngIf="
                collection.confirmationRequired &&
                member.liuId === currentUser &&
                !member.membershipConfirmed
              "
            >
              <app-group-accept-button
                (acceptEvent)="acceptGroup($event)"
                (denyEvent)="denyGroup($event)"
                [groupId]="group.id"
                [accepted]="confirmedGroup"
              >
              </app-group-accept-button>
            </ng-container>
          </td>
        </tr>
        <tr
          *ngIf="
            !collection.confirmationRequired &&
            (registredGroups.includes(group.id) ||
              group.users.length < collection.groupSize)
          "
          class="row-add-me table-primary"
        >
          <td colspan="3">
            <app-group-register-button
              (unregistredEvent)="unregistredEvent($event)"
              (registredEvent)="registredEvent($event)"
              [isRegistred]="registredGroups.includes(group.id)"
              [courseId]="currentCourseId"
              [courseCollectionId]="collection.id"
              [courseGroupId]="group.id"
              [hidden]="
                registredCollections.includes(collection.id) &&
                !registredGroups.includes(group.id)
              "
            >
            </app-group-register-button>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <ng-container
        *ngTemplateOutlet="new_group; context: {visible: ((collection.groups.length < collection.maxNumberOfGroups) && !registredCollections.includes(collection.id)), collectionId: collection.id}"
    ></ng-container> -->
  </div>
</ng-template>

<ng-template #new_group let-visible="visible" let-collectionId="collectionId">
  <div *ngIf="visible">
    <table>
      <caption>
        <em class="left">
          <span> Ny grupp </span>
        </em>
      </caption>
      <thead>
        <tr>
          <th class="mail-column">
            <i class="bi-envelope"></i>
          </th>
          <th class="student-column">Student</th>
          <th class="actions-column">Actions</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td class="mail-column"></td>
          <td></td>
          <td class="actions-column">
            <app-group-register-button
              (unregistredEvent)="unregistredEvent($event)"
              (registredEvent)="registredEvent($event)"
              [isRegistred]="false"
              [courseId]="currentCourseId"
              [courseCollectionId]="collectionId"
              [courseGroupId]="-1"
            ></app-group-register-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</ng-template>
