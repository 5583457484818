import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WritingPart } from 'src/models/course-writing/writing-part';
import { WritingQuestion } from 'src/models/course-writing/writing-question';

@Injectable({
  providedIn: 'root',
})
export class WritingAssignmentService {
  private writingPartProperty = new BehaviorSubject<WritingPart>(null);
  public $writingPart = this.writingPartProperty.asObservable();

  private questionProperty = new BehaviorSubject<WritingQuestion[]>(null);
  public $question = this.questionProperty.asObservable();

  constructor() {}

  setWritingPart(writing: WritingPart) {
    this.writingPartProperty.next(writing);
  }
}
