import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'imageUrl',
})
export class ImageUrlPipe implements PipeTransform {
  transform(id: number | string, placeholder: boolean = false): string {
    return `${environment.api}/image/${id}${placeholder ? '/placeholder' : ''}`;
  }
}
