import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/services/auth.service';
import { LogInService } from 'src/app/services/log-in.service';
import { NotificationsDataService } from 'src/app/services/notifications-data.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { UserMenuService } from 'src/app/services/user-menu.service';
import { environment } from 'src/environments/environment';
import { ToolbarLink } from 'src/models/toolbar-link';
import { ToolbarTitle } from 'src/models/toolbar-title';

@UntilDestroy()
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
  public static readonly ICON_NOTIF_INDEX = 1;

  title: ToolbarTitle;

  readonly defaultLinks: ToolbarLink[] = [
    {
      icon: 'house-door-fill',
      name: 'Mina kurser',
      link: '/courses',
    },
    {
      icon: 'bell-fill',
      name: 'Notifikationer',
      onClick: () => this.toggleNotificationsVisible(),
    },
  ];

  usernameChar: string;

  activatedMainRoute = '';
  customLinks: ToolbarLink[];
  userMenuVisible: boolean;

  constructor(
    private toolbarService: ToolbarService,
    private router: Router,
    private location: Location,
    private auth: AuthService,
    private loginService: LogInService,
    private userMenu: UserMenuService,
    private notificationsService: NotificationsDataService
  ) {}

  ngOnInit(): void {
    this.toolbarService.toolbarTitleObservable
      .pipe(untilDestroyed(this))
      .subscribe((title) => (this.title = title));

    this.router.events.pipe(untilDestroyed(this)).subscribe((url) => {
      let curPath = this.location.path();
      if (curPath.length) {
        this.activatedMainRoute = curPath.split('/')[1];
      } else {
        this.activatedMainRoute = '';
      }
    });

    this.toolbarService.customToolbarItemsObservable
      .pipe(untilDestroyed(this))
      .subscribe((links) => (this.customLinks = links));

    this.auth.$jwt.pipe(untilDestroyed(this)).subscribe((jwt) => {
      if (jwt) {
        this.onUsernameChange(jwt.name);
      }
    });

    this.userMenu.$userMenuVisible
      .pipe(untilDestroyed(this))
      .subscribe((visible) => (this.userMenuVisible = visible));

    this.userMenu.$notificationsVisible
      .pipe(untilDestroyed(this))
      .subscribe(
        (visible) =>
          (this.defaultLinks[ToolbarComponent.ICON_NOTIF_INDEX].forceActivated =
            visible)
      );

    this.notificationsService.$notifications
      .pipe(untilDestroyed(this))
      .subscribe(
        (notifications) =>
          (this.defaultLinks[
            ToolbarComponent.ICON_NOTIF_INDEX
          ].notificationsAmount = notifications?.length)
      );
  }

  handleSignOut() {
    this.auth.signOut();
  }

  toggleNotificationsVisible() {
    this.userMenu.toggleUserNotificationsVisible();
  }

  navigate(link: string) {
    this.router.navigateByUrl(link);
  }

  onUsernameChange(username: string) {
    if (!username?.length) {
      this.usernameChar = null;
      return;
    }

    this.usernameChar = username[0].toUpperCase();
  }
}
