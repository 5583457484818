<h2 class="activity-title">
  <span *ngIf="activity" [hidden]="!activity"
    >{{ subject }} - {{ activity?.title }}</span
  >
  <h5 *ngIf="activity && activity.deadline !== null">
    <i class="bi-alarm"></i>
    Deadline för momentet är
    {{ activity?.deadline | date: 'MMM d, y, HH:mm' }}
  </h5>
  <span *ngIf="staticPart" [hidden]="!staticPart">{{ staticPart?.title }}</span>
</h2>

<app-course-start
  *ngIf="isNews"
  [hidden]="!isNews"
  [active]="isNews"
></app-course-start>

<app-course-calendar
  *ngIf="isCalendar"
  [hidden]="!isCalendar"
></app-course-calendar>

<app-quiz *ngIf="isQuiz" [hidden]="!isQuiz" [active]="isQuiz"></app-quiz>

<app-writing
  *ngIf="isWriting"
  [hidden]="!isWriting"
  [active]="isWriting"
></app-writing>

<app-informative-activity
  *ngIf="isInformative"
  [hidden]="!isInformative"
  [materials]="isInformative ? activityData?.content : []"
></app-informative-activity>

<app-seminar
  *ngIf="isSeminar"
  [hidden]="!isSeminar"
  [seminar]="isSeminar ? activityData : null"
></app-seminar>

<app-assignment
  *ngIf="isAssignment"
  [hidden]="!isAssignment"
  [assignment]="isAssignment ? activityData : null"
></app-assignment>

<app-course-results
  *ngIf="isResults"
  [hidden]="!isResults"
  [active]="isResults"
></app-course-results>

<app-groups *ngIf="isGroups" [hidden]="!isGroups"></app-groups>
