import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CourseDataService } from 'src/app/services/course-data.service';
import { CoursePageService } from 'src/app/services/course-page.service';
import { QuizService } from 'src/app/services/quiz.service';
import { CourseActivityPointDescription } from 'src/models/course-activity-points';

import { AnswerCheckResult } from 'src/models/quiz/quiz-answer-check-result';
import { QuizPart } from 'src/models/quiz/quiz-part';

@UntilDestroy()
@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  @Input() set active(active: boolean) {
    if (active) {
      this.quizService.setQuizPartIndex(0);
    }
  }

  activeIndex: number;
  quizPart: QuizPart;
  improvementTexts: string[];
  activityPointsData = {
    criterias: [],
    pointSummary: {},
  };

  /* startTime: Date;
  endTime: Date; 
  duration = '';
  ellapsedTime = '00:00'; //show this first*/

  constructor(
    private quizService: QuizService,
    private courseDataService: CourseDataService,
    private coursePageService: CoursePageService
  ) {}

  ngOnInit(): void {
    this.quizService.$curQuizPart
      .pipe(untilDestroyed(this))
      .subscribe((part) => (this.quizPart = part));

    this.quizService.$curQuizPartIndex
      .pipe(untilDestroyed(this))
      .subscribe((index) => (this.activeIndex = index));

    this.quizService.$results
      .pipe(untilDestroyed(this))
      .subscribe((results) => (this.improvementTexts = results));

    this.coursePageService.$curActivityId.subscribe((activityId) => {
      if (activityId) {
        this.courseDataService.getActivityPoint(activityId).subscribe((res) => {
          this.activityPointsData = res;
        });
      }
    });
  }

  /* tick() { //klockan måste startas någonstans
    const now = new Date(); 
    const diff = (now.getTime() + this.startTime.getTime());

    this.ellapsedTime = this.timeTaken(diff);
  }

  timeTaken(totalTime: number){
    let mins: string | number = Math.floor(totalTime / 60);
    let secs: string | number = Math.round(totalTime % 60); 

    return `${mins}:${secs}`;
  }*/
}
