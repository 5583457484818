<div class="root" (click)="select()">
  <ng-container *ngIf="curNotificationBase?.type == gradeNotificationType">
    <h6>
      {{ gradeNotification.courseCode }}
    </h6>
    <p>Nytt resultat: {{ gradeNotification.activityTitle }}</p>
  </ng-container>

  <ng-container *ngIf="curNotificationBase?.type == newsNotificationType">
    <h6>
      {{ newsNotification.courseCode }}
    </h6>
    <p>{{ newsNotification.amount }} st kursnyheter</p>
  </ng-container>

  <ng-container *ngIf="curNotificationBase?.type == activityReminderType">
    <h6>{{ activityReminderNotification.courseCode }} Påminnelse</h6>
    <p>Kursaktivitet {{ activityReminderNotification.activityTitle }}</p>
    <p>
      Deadline:
      {{
        activityReminderNotification.deadline | date: 'MMM d, y, HH:mm':'GMT+1'
      }}
    </p>
  </ng-container>

  <ng-container
    *ngIf="curNotificationBase?.type == activityResultNotificationType"
  >
    <h6>Nytt betyg</h6>
    <p>Kursaktivitet: {{ activityResultNotification.activityTitle }}</p>
    <p>
      Betyg:
      {{ activityResultNotification.grade | gradeLabel }}
    </p>
  </ng-container>
  <ng-container
    *ngIf="curNotificationBase?.type == courseResultNotificationType"
  >
    <h6>Nytt kursbetyg</h6>
    <p>Kurs: {{ courseResultNotification.courseCode }}</p>
    <p>
      Betyg:
      {{ courseResultNotification.grade | gradeLabel }}
    </p>
  </ng-container>

  <ng-container *ngIf="curNotificationBase?.type == groupReminderType">
    <h6>{{ groupReminderNotification.courseCode }} Påminnelse</h6>
    <p>Anmälan {{ groupReminderNotification.collectionTitle }}</p>
    <p>
      Deadline:
      {{ groupReminderNotification.deadline | date: 'MMM d, y, HH:mm':'GMT+1' }}
    </p>
  </ng-container>

  <div class="date-text">
    {{ curNotificationBase?.date | date }}
  </div>
</div>
