<ng-container
  *ngTemplateOutlet="navBtn; context: { item: prevActivity, isForward: false }"
></ng-container>

<ng-container *ngTemplateOutlet="finishedBtn"></ng-container>

<ng-container
  *ngTemplateOutlet="navBtn; context: { item: nextActivity, isForward: true }"
></ng-container>

<ng-template #finishedBtn>
  <button
    type="button"
    class="btn marker"
    [class.btn-primary]="!curActivity?.finished"
    [class.btn-success]="curActivity?.finished"
    *ngIf="curActivity?.isAdminGraded && !curActivity?.grade ? false : true"
    [disabled]="
      isStaticPage ||
      !curActivity ||
      curActivity?.isAdminGraded ||
      !curActivity?.unlocked
    "
    (click)="toggleDone()"
  >
    <span *ngIf="curActivity?.grade">
      {{ curActivity?.grade.grade >= 2 ? 'Godkänd' : 'Ej godkänd' }}
    </span>
    <span *ngIf="!curActivity?.grade">
      {{ curActivity?.finished ? 'Markera ej klar' : 'Markera klar' }}
    </span>

    <i
      [class]="curActivity?.finished ? 'bi-check-circle-fill' : 'bi-check-circle'"
    ></i>
  </button>
</ng-template>

<ng-template #navBtn let-item="item" let-isForward="isForward">
  <button
    type="button"
    class="btn btn-primary nav"
    *ngIf="item && item?.id >= 0"
    [class.isForward]="isForward"
    [disabled]="!item || !item?.unlocked"
    (click)="isForward ? navForward() : navBack()"
  >
    <i
      [class]="
        !item?.unlocked
          ? 'bi-lock-fill'
          : isForward
          ? 'bi-chevron-right'
          : 'bi-chevron-left'
      "
    ></i>
    <span [hidden]="!item">
      {{ item?.title }}
    </span>
  </button>
</ng-template>
