import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { CourseDataService } from 'src/app/services/course-data.service';
import { CoursePageService } from 'src/app/services/course-page.service';
import { CourseActivity } from 'src/models/course-activity';
import { CourseActivityIndex } from 'src/models/course-activity-index';
import { CoursePart } from 'src/models/course-part';
import { NavItem } from 'src/models/nav-item';
import { StaticCoursePart } from 'src/models/static-course-part';

@UntilDestroy()
@Component({
  selector: 'app-navigation-controls',
  templateUrl: './navigation-controls.component.html',
  styleUrls: ['./navigation-controls.component.scss'],
})
export class NavigationControlsComponent implements OnInit {
  curActivityId: number;

  prevActivity: NavItem;
  curActivity: NavItem;
  nextActivity: NavItem;

  isStaticPage = true;

  constructor(
    private courseService: CoursePageService,
    private courseDataService: CourseDataService
  ) {}

  ngOnInit(): void {
    this.courseService.$curActivityId
      .pipe(untilDestroyed(this))
      .subscribe((id) => this.setActivityId(id));

    this.courseDataService.$course
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateActivities());
  }

  navBack() {
    this.courseService.navBack();
  }

  navForward() {
    this.courseService.navForward();
  }

  toggleDone() {
    this.courseService
      .setCurActivityDone(!this.curActivity?.finished)
      .subscribe();
  }

  private setActivityId(id: number) {
    this.curActivityId = id;

    this.isStaticPage = id < 0;
    this.updateActivities();
  }

  private updateActivities() {
    let navItems = this.courseService.getNavItems();
    this.prevActivity = navItems[0];
    this.curActivity = navItems[1];
    this.nextActivity = navItems[2];
  }
}
