import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JWT, Tokens } from 'src/models/auth';
import { environment } from '../../environments/environment';
import jwtDecode from 'jwt-decode';
import { Router } from '@angular/router';
import Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly JWT_TOKEN = 'JWT_TOKEN';
  private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

  private jwtSubject = new BehaviorSubject<JWT>(null);
  public $jwt = this.jwtSubject.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    this.moveCookieTokensToLocalStorage();
    const refreshToken = this.getRefreshToken();
    const accessToken = this.getAccessToken();

    if (refreshToken && accessToken) {
      this.setTokens({ refreshToken, accessToken });
    }
  }

  public isLoggedIn() {
    return !!this.jwtSubject.value;
  }

  public login() {
    if (!environment.production) {
      // Only for development
      return this.http.get<any>(`${environment.api}/auth/jwt?`).pipe(
        tap((tokens) => {
          this.setTokens(tokens);
          this.router.navigate(['/courses']);
        })
      );
    }

    return this.http
      .post<{ type: string; url: string }>(
        `${environment.api}/saml/login-request?next=https://eproflearn.ida.liu.se`,
        {},
        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
          if (
            response.status === 200 &&
            response.body.type === 'SAML login request'
          ) {
            window.location.href = response.body.url;
          }
        })
      );
  }

  public refreshToken() {
    const refreshToken = this.getRefreshToken();
    return this.http
      .post<Tokens>(`${environment.api}/auth/refresh`, {
        refreshToken: refreshToken,
      })
      .pipe(
        tap((tokens) => {
          this.setTokens(tokens);
        })
      );
  }

  public signOut() {
    this.clearTokens();
    this.jwtSubject.next(null);
    this.router.navigate(['/']);
  }

  public getJwtToken() {
    return localStorage.getItem(this.JWT_TOKEN) ?? '';
  }

  private getRefreshToken() {
    return localStorage.getItem(this.REFRESH_TOKEN) ?? '';
  }

  private getAccessToken() {
    return localStorage.getItem(this.JWT_TOKEN) ?? '';
  }

  private moveCookieTokensToLocalStorage() {
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken');

    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');

    if (accessToken && refreshToken) {
      localStorage.setItem(this.REFRESH_TOKEN, refreshToken);
      localStorage.setItem(this.JWT_TOKEN, accessToken);
    }
  }

  private setTokens(tokens: Tokens) {
    localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
    localStorage.setItem(this.JWT_TOKEN, tokens.accessToken);

    if (tokens.accessToken) {
      try {
        const jwt = jwtDecode<JWT>(tokens.accessToken);
        this.jwtSubject.next(jwt);
      } catch (error) {
        console.log('Failed to decode JWT', error);
      }
    }
  }

  private clearTokens() {
    localStorage.removeItem(this.JWT_TOKEN);
    localStorage.removeItem(this.REFRESH_TOKEN);
  }
}
