import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { QuizService } from 'src/app/services/quiz.service';
import { QuizPartType } from 'src/enums/quiz-part-type.enum';
import { QuizPart } from 'src/models/quiz/quiz-part';
import { QuizSelectPart } from 'src/models/quiz/quiz-select-part';

@UntilDestroy()
@Component({
  selector: 'app-quiz-answers-select',
  templateUrl: './quiz-answers-select.component.html',
  styleUrls: ['./quiz-answers-select.component.scss'],
})
export class QuizAnswersSelectComponent implements OnInit {
  curPart: QuizSelectPart;
  isPostState = false;

  selected: boolean[];

  constructor(private quizService: QuizService) {}

  ngOnInit(): void {
    this.quizService.$isPostState
      .pipe(untilDestroyed(this))
      .subscribe((isPostState) => this.setIsPostState(isPostState));

    this.quizService.$curQuizPart
      .pipe(untilDestroyed(this))
      .subscribe((part) => this.setCurPart(part));

    this.quizService.$onRetry
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onRetry());
  }

  setIsPostState(isPostState: boolean) {
    this.isPostState = isPostState;

    if (this.isPostState) {
      this.performAnswersCheck();
    }
  }

  toggleSelect(optionIndex: number) {
    if (this.isPostState) {
      return;
    }

    if (!this.curPart.isMultipleSelect) {
      this.selected.fill(false);
    }

    this.selected[optionIndex] = !this.selected[optionIndex];

    this.onSelectChange();
  }

  private onRetry() {
    this.resetSelected();
  }

  private setCurPart(part: QuizPart) {
    if (part?.type === QuizPartType.selection) {
      this.curPart = part as QuizSelectPart;
      this.generateBooleanLists();

      this.quizService.setHasAnswers(false);
    } else {
      this.curPart = null;
      this.selected = null;
    }
  }

  private performAnswersCheck() {
    let firstIncorrectIndex = _.findIndex(
      this.selected,
      (isSelected, index) =>
        isSelected !== this.curPart?.options[index].isCorrect
    );

    let correct = firstIncorrectIndex < 0;

    this.quizService.setAnswerResult({
      correct,
      description: correct ? 'Rätt svarat' : 'Fel',
    });
  }

  private generateBooleanLists() {
    const len = this.curPart.options.length;
    this.selected = Array(len).fill(false);
  }

  private resetSelected() {
    this.selected.fill(false);
    this.onSelectChange();
  }

  private onSelectChange() {
    const anySelected = _.findIndex(this.selected) >= 0;
    this.quizService.setHasAnswers(anySelected);
  }
}
