import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CourseItemsComponent } from './components/course-page/components/course-items/course-items.component';
import { CourseCardComponent } from './components/course-overviews-list/components/course-card/course-card.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CourseOverviewsListComponent } from './components/course-overviews-list/course-overviews-list.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { CoursePageComponent } from './components/course-page/course-page.component';
import { NavigationControlsComponent } from './components/course-page/components/navigation-controls/navigation-controls.component';
import { CourseContentComponent } from './components/course-page/components/course-content/course-content.component';

import { CourseStartComponent } from './components/course-page/components/course-content/components/course-start/course-start.component';
import { NewsComponent } from './components/course-page/components/course-content/components/course-start/components/news/news.component';
import { QuizComponent } from './components/course-page/components/course-content/components/quiz/quiz.component';
import { QuizAnswersSelectComponent } from './components/course-page/components/course-content/components/quiz/components/quiz-answers-select/quiz-answers-select.component';
import { QuizNavComponent } from './components/course-page/components/quiz-nav/quiz-nav.component';
import { FeedbackComponent } from './components/course-page/components/feedback/feedback.component';
import { VideoContentComponent } from './components/course-page/components/course-content/components/informative-activity/components/video-content/video-content.component';
import { CourseCalendarComponent } from './components/course-page/components/course-content/components/course-calendar/course-calendar.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { AccountPageComponent } from './components/account-page/account-page.component';
import { AccountInfoComponent } from './components/account-page/components/account-info/account-info.component';
import { SubmissionFeedbackComponent } from './components/course-page/components/course-content/components/writing/components/submission-feedback/submission-feedback.component';
import { WritingComponent } from './components/course-page/components/course-content/components/writing/writing.component';
import { AssignmentFormComponent } from './components/course-page/components/course-content/components/writing/components/assignment-form/assignment-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InformativeActivityComponent } from './components/course-page/components/course-content/components/informative-activity/informative-activity.component';
import { InfoCardComponent } from './components/course-page/components/course-content/components/informative-activity/components/info-card/info-card.component';
import { PdfContentComponent } from './components/course-page/components/course-content/components/informative-activity/components/pdf-content/pdf-content.component';
import { CourseItemComponent } from './components/course-page/components/course-content/components/informative-activity/components/course-item/course-item.component';
import { ImageContentComponent } from './components/course-page/components/course-content/components/informative-activity/components/image-content/image-content.component';
import { NotificationsComponent } from './components/menus/notifications/notifications.component';
import { NotificationComponent } from './components/menus/notifications/components/notification/notification.component';
import { MenuContainerComponent } from './components/menu-container/menu-container.component';
import { ActivityGradeCriteriaComponent } from './components/course-page/components/activity-grade-criteria/activity-grade-criteria.component';
import { SeminarComponent } from './components/course-page/components/course-content/components/seminar/seminar.component';
import { GradeLabelPipe } from './pipes/grade-label.pipe';
import { GradeKeyPipe } from './pipes/grade-key.pipe';
import { CourseResultsComponent } from './components/course-page/components/course-content/components/course-results/course-results.component';
import { ExpandableBoxComponent } from './components/expandable-box/expandable-box.component';
import { ResultLabelComponent } from './components/result-label/result-label.component';
import { IsNumberPipe } from './pipes/is-number.pipe';
import { AccountBadgesComponent } from './components/account-page/components/account-badges/account-badges.component';
import { ToastsContainerComponent } from './components/toasts-container/toasts-container.component';
import { ImageUrlPipe } from './pipes/image-url.pipe';
import { GradeCriteriasDescriptionWrapperComponent } from './components/course-page/components/grade-criterias-description-wrapper/grade-criterias-description-wrapper.component';
import { LazyLoadPreviewImageComponent } from './components/lazy-load-preview-image/lazy-load-preview-image.component';
import { PointLabelComponent } from './components/course-page/components/course-content/components/course-results/components/point-label/point-label.component';
import { BadgeLabelComponent } from './components/course-page/components/course-content/components/course-results/components/badge-label/badge-label.component';
import { AssignmentComponent } from './components/course-page/components/course-content/components/assignment/assignment.component';
import { AssignmentFeedbackComponent } from './components/course-page/components/course-content/components/assignment/components/assignment-feedback/assignment-feedback.component';
import { GroupsComponent } from './components/course-page/components/course-content/components/groups/groups.component';
import { GroupRegisterButtonComponent } from './components/course-page/components/course-content/components/groups/components/group-register-button/group-register-button.component';
import { GroupAcceptButtonComponent } from './components/course-page/components/course-content/components/groups/components/group-accept-button/group-accept-button.component';
import { ComplementaryComponent } from './components/course-page/components/course-content/components/seminar/components/complementary/complementary.component';
import { ActivityPointOverviewComponent } from './components/course-page/components/course-content/components/activity-point-overview/activity-point-overview.component';
import { CoursePointOverviewComponent } from './components/course-page/components/course-content/components/course-point-overview/course-point-overview.component';
import { CoursePartPointOverviewComponent } from './components/course-page/components/course-content/components/course-part-point-overview/course-part-point-overview.component';
import { CourseProgressComponent } from './components/course-page/components/course-items/components/course-progress/course-progress.component';
import { LoginPageComponent } from './components/login-page/login-page.component';
import { SubmissionFileComponent } from './components/course-page/components/course-content/components/assignment/components/submission-file/submission-file.component';
import { CourseInfoComponent } from './components/course-page/components/course-content/components/course-start/components/course-info/course-info.component';
import { FeatureFlagService } from './services/feature-flag.service';
import { FeatureFlagDirective } from './directives/feature-flag.directive';

@NgModule({
  declarations: [
    AppComponent,
    CourseItemsComponent,
    CourseCardComponent,
    ProgressBarComponent,
    CourseOverviewsListComponent,
    ToolbarComponent,
    CoursePageComponent,
    NavigationControlsComponent,
    CourseContentComponent,
    InfoCardComponent,
    CourseStartComponent,
    NewsComponent,
    QuizComponent,
    QuizAnswersSelectComponent,
    QuizNavComponent,
    FeedbackComponent,
    VideoContentComponent,
    ImageContentComponent,
    CourseCalendarComponent,
    CourseItemsComponent,
    CourseItemComponent,
    PdfContentComponent,
    AccountPageComponent,
    AccountInfoComponent,
    SubmissionFeedbackComponent,
    WritingComponent,
    AssignmentFormComponent,
    InformativeActivityComponent,
    NotificationsComponent,
    NotificationComponent,
    MenuContainerComponent,
    ActivityGradeCriteriaComponent,
    SeminarComponent,
    GradeLabelPipe,
    GradeKeyPipe,
    CourseResultsComponent,
    ExpandableBoxComponent,
    ResultLabelComponent,
    IsNumberPipe,
    AccountBadgesComponent,
    ToastsContainerComponent,
    ImageUrlPipe,
    GradeCriteriasDescriptionWrapperComponent,
    LazyLoadPreviewImageComponent,
    PointLabelComponent,
    BadgeLabelComponent,
    AssignmentComponent,
    AssignmentFeedbackComponent,
    GroupsComponent,
    GroupRegisterButtonComponent,
    GroupAcceptButtonComponent,
    ComplementaryComponent,
    ActivityPointOverviewComponent,
    CoursePointOverviewComponent,
    CoursePartPointOverviewComponent,
    CourseProgressComponent,
    LoginPageComponent,
    SubmissionFileComponent,
    CourseInfoComponent,
    FeatureFlagDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'en-SE' },
    { provide: Window, useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (featureFlagService: FeatureFlagService) => () =>
        featureFlagService.loadConfig(),
      deps: [FeatureFlagService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
