import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-start',
  templateUrl: './course-start.component.html',
  styleUrls: ['./course-start.component.scss'],
})
export class CourseStartComponent implements OnInit {
  @Input() active: boolean;
  constructor() {}

  ngOnInit(): void {}
}
