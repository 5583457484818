import * as _ from 'lodash';
import { Course } from 'src/models/course';
import { CourseActivity } from 'src/models/course-activity';
import { CourseActivityIndex } from 'src/models/course-activity-index';

export class CourseHelper {
  public static getActivity(
    activityId: number,
    course: Course
  ): CourseActivity {
    return CourseHelper.getLodashActivitiesObject(course)
      .find((activity) => activity.id === activityId)
      .value();
  }

  public static getActivityByIndex(
    activityIndex: CourseActivityIndex,
    course: Course
  ): CourseActivity {
    return _.nth(
      _.nth(course.parts, activityIndex.part)?.activities,
      activityIndex.activity
    );
  }

  public static getActivityIndex(
    activityId: number,
    course: Course
  ): CourseActivityIndex {
    let foundPartIndex = -1;
    let foundActivityIndex = -1;

    _.forEach(course.parts, (part, partIndex) => {
      _.forEach(part.activities, (activity, activityIndex) => {
        if (activity.id === activityId) {
          foundActivityIndex = activityIndex;
          return false;
        }
      });

      if (foundActivityIndex >= 0) {
        foundPartIndex = partIndex;
        return false;
      }
    });

    return {
      part: foundPartIndex,
      activity: foundActivityIndex,
    };
  }

  public static getCourseActivities(course: Course): CourseActivity[] {
    return CourseHelper.getLodashActivitiesObject(course).value();
  }

  public static getPartIndexForActivityId(activityId: number, course: Course) {
    let res = -1;
    _.forEach(course?.parts, (part, index) => {
      if (_.find(part.activities, { id: activityId })) {
        res = index;
        return false;
      }
    });

    return res;
  }

  private static getLodashActivitiesObject(
    course: Course
  ): _.CollectionChain<CourseActivity> {
    return _.chain(course.parts)
      .map((part) => part.activities)
      .flatten();
  }
}
