import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CoursePageService } from 'src/app/services/course-page.service';
import { FeedbackService } from 'src/app/services/feedback.service';
import { QuizService } from 'src/app/services/quiz.service';
import { FeedbackMessage } from 'src/models/feedback-msg';

@UntilDestroy()
@Component({
  selector: 'app-quiz-nav',
  templateUrl: './quiz-nav.component.html',
  styleUrls: ['./quiz-nav.component.scss'],
})
export class QuizNavComponent implements OnInit {
  public static readonly POSITIVE_MSG: FeedbackMessage = {
    positive: true,
    msg: 'Bra jobbat!',
    iconName: '',
  };

  public static readonly NEGATIVE_MSG = {
    positive: false,
    msg: 'Försök igen.',
    iconName: '',
  };

  isPostState: boolean;
  isSummaryPage: boolean;
  isCorrect: boolean;
  canCheck: boolean;

  partsAmount: number;
  progress: number;

  constructor(
    private quizService: QuizService,
    private feedbackService: FeedbackService,
    private coursePageService: CoursePageService
  ) {}

  ngOnInit(): void {
    this.quizService.$isPostState
      .pipe(untilDestroyed(this))
      .subscribe((isPoststate) => (this.isPostState = isPoststate));

    this.quizService.$answerResult
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        if (result) {
          this.setIsCorrect(result?.correct);
        }
      });

    this.quizService.$curQuiz
      .pipe(untilDestroyed(this))
      .subscribe((quiz) => (this.partsAmount = quiz?.parts?.length));

    this.quizService.$hasAnswers
      .pipe(untilDestroyed(this))
      .subscribe((hasAnswers) => (this.canCheck = hasAnswers));

    this.quizService.$curQuizPartIndex
      .pipe(untilDestroyed(this))
      .subscribe((index) => this.updateProgress(index));

    this.quizService.$results
      .pipe(untilDestroyed(this))
      .subscribe((results) => (this.isSummaryPage = !!results));
  }

  onNextClick() {
    if (this.isSummaryPage) {
      this.coursePageService.navForward();
    } else if (this.isPostState) {
      if (this.isCorrect) {
        this.quizService.goToNextQuizPart();
      } else {
        this.quizService.retry();
      }
      this.feedbackService.clearMessage();
    } else {
      this.quizService.setIsPostState(true);
    }
  }

  private setIsCorrect(isCorrect: boolean) {
    this.isCorrect = isCorrect;
    this.showFeedback();
  }

  private showFeedback() {
    this.feedbackService.showMessage(
      this.isCorrect
        ? QuizNavComponent.POSITIVE_MSG
        : QuizNavComponent.NEGATIVE_MSG
    );
  }

  private updateProgress(index: number) {
    if (this.partsAmount > 0) {
      this.progress = Math.round((100 * index) / this.partsAmount);
    } else {
      this.progress = 0;
    }
  }
}
