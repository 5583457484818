<div
  class="root"
  [class.show]="!hide"
  [class.hide]="hide"
  [class.positive]="msg?.positive"
  [class.negative]="msg && !msg?.positive"
>
  <h4>
    <i [class]="msg?.positive ? 'bi-check-circle-fill' : 'bi-x-circle-fill'"></i>
    {{ msg?.msg }}
  </h4>
</div>
