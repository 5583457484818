import { Component, HostListener, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from './services/auth.service';
import { UserMenuService } from './services/user-menu.service';
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'exjobb-e-learning';

  scrollTop = undefined;
  hideToolbarForSmall = false;

  displaySideMenu = false;

  userMenuVisible = false;
  notificationsVisible = false;

  isLoggedIn: boolean;

  constructor(
    private userMenuService: UserMenuService,
    private auth: AuthService
  ) {}

  ngOnInit() {
    this.userMenuService.$userMenuVisible
      .pipe(untilDestroyed(this))
      .subscribe((visible) => (this.userMenuVisible = visible));

    this.userMenuService.$notificationsVisible
      .pipe(untilDestroyed(this))
      .subscribe((visible) => (this.notificationsVisible = visible));

    this.auth.$jwt.subscribe((jwt) => {
      if (jwt) {
        this.isLoggedIn = true;
      } else {
        this.isLoggedIn = false;
      }
    });
  }

  @HostListener('window:scroll', ['$event']) // for window scroll events
  onScroll(e) {
    if (window.scrollY <= 0) {
      this.hideToolbarForSmall = false;
    } else if (window.scrollY >= window.innerHeight) {
      this.hideToolbarForSmall = true;
    } else {
      this.hideToolbarForSmall = this.scrollTop < window.scrollY;
    }
    this.hideToolbarForSmall = false;

    this.scrollTop = window.scrollY;
  }

  openSideMenu() {
    this.displaySideMenu = true;
  }

  hideUserMenu() {
    this.userMenuService.setUserMenuVisible(false);
  }

  hideNotifications() {
    this.userMenuService.setNotificationsVisible(false);
  }
}
