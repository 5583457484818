import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CourseDataService } from 'src/app/services/course-data.service';

import { CoursePageService } from 'src/app/services/course-page.service';
import { CourseOverview } from 'src/models/course-overview';
import { CourseGrades } from 'src/models/course-grades.ts';

@UntilDestroy()
@Component({
  selector: 'app-course-card',
  templateUrl: './course-card.component.html',
  styleUrls: ['./course-card.component.scss'],
})
export class CourseCardComponent implements OnInit {
  readonly courseStartActivity = CoursePageService.STATIC_COURSE_PARTS[0].id;

  @Input() courseData: CourseOverview;

  @Input() courseGrades: CourseGrades;

  @Input() isTakenCourse = true;

  getCourseLevelLabel(level_number: number): string {
    const levels = { 1: 'Grundkurs', 2: 'Avancerad' };
    return levels[level_number] ?? levels[1];
  }

  constructor(private courseDataService: CourseDataService) {}

  ngOnInit(): void {
    this.courseDataService.$results.subscribe((results) => {
      this.courseGrades = results;
    });
  }
}
