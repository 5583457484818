<nav
  ngbNav
  #nav="ngbNav"
  class="nav-tabs responsive-nav equal-widths"
  [(activeId)]="activeTabIndex"
  (navChange)="onTabChange($event)"
>
  <ng-container [ngbNavItem]="1">
    <a ngbNavLink>Beskrivning</a>
    <ng-template ngbNavContent>
      <app-informative-activity
        [materials]="assignment?.content"
      ></app-informative-activity>
      <app-activity-point-overview
        *ngIf="activityPointsData?.criterias?.length != 0"
        [activityCriterias]="activityPointsData.criterias"
        [pointSummary]="activityPointsData.pointSummary"
        [withResult]="false"
      >
      </app-activity-point-overview>
    </ng-template>
  </ng-container>

  <ng-container [ngbNavItem]="2">
    <a ngbNavLink>Inlämning</a>
    <ng-template ngbNavContent>
      <ngb-alert *ngIf="submitted" [dismissible]="false" type="success">
        Du har redan lämnat in denna uppgift. Klicka på 'Lämna in' för att lämna
        in en ny version.
      </ngb-alert>
      {{ infoText }}
      <br /><br />
      <form>
        <div class="input-group" (click)="fileUpload.click()">
          <input
            type="file"
            class="hidden"
            (change)="onFileSelected($event)"
            accept="application/pdf"
            #fileUpload
          />
          <input
            type="text"
            class="form-control"
            [placeholder]="this.file ? this.file.name : 'Fil'"
            aria-describedby="file-upload-btn"
            readonly
          />
          <div class="input-group-append">
            <button class="btn btn-primary" type="button" id="file-upload-btn">
              Välj fil
            </button>
          </div>
        </div>
        <p>PDFen får ej vara större än 10MB.</p>

        <br />
        <div class="form-group" *ngIf="gradingMotivationRequired">
          <label for="comment" style="white-space: pre-line">
            <h5>
              Önskat betyg
              <span style="color: orangered" [ngbTooltip]="'Obligatorisk'"
                ><b>*</b></span
              >
            </h5>
            Skriv ditt önskade betyg tillsammans med en motivering.<br />
          </label>
          <textarea
            required
            placeholder="Skriv här..."
            id="comment"
            cols="30"
            rows="3"
            class="form-control"
            [formControl]="gradingMotivation"
          ></textarea>
        </div>
        <ng-template #content let-modal>
          <div class="modal-header">
            <h4>Lämna in</h4>
            <button
              type="button"
              class="close"
              aria-label="Close"
              (click)="modal.dismiss(false)"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>Är du säker på att du vill lämna in?</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-primary" (click)="modal.close(true)">
              Lämna in
            </button>
          </div>
        </ng-template>

        <button
          class="btn btn-primary"
          [disabled]="
            assignmentResult?.grade >= 2 ||
            gradingMotivationRequired === 0 ||
            (gradingMotivationRequired === 1 &&
              gradingMotivation.value === '') ||
            !file
          "
          (click)="open(content)"
        >
          Lämna in
        </button>
      </form>
    </ng-template>
  </ng-container>

  <ng-container [ngbNavItem]="3">
    <a ngbNavLink>Bedömning</a>
    <ng-template ngbNavContent>
      <app-grade-criterias-description-wrapper
        [grade]="assignmentResult"
      ></app-grade-criterias-description-wrapper>
      <app-activity-point-overview
        *ngIf="activityPointsData?.criterias?.length != 0"
        [activityCriterias]="activityPointsData.criterias"
        [pointSummary]="activityPointsData.pointSummary"
        [withResult]="true"
      >
      </app-activity-point-overview>
    </ng-template>
  </ng-container>

  <ng-container [ngbNavItem]="4">
    <a ngbNavLink>Historik</a>
    <ng-template ngbNavContent>
      <app-assignment-feedback
        [activityFileSubmissions]="activityFileSubmissions"
        [assignmentResult]="assignmentResult"
      ></app-assignment-feedback>
    </ng-template>
  </ng-container>

  <ng-container
    *ngIf="assignment?.content.requiredComplementary"
    [ngbNavItem]="5"
  >
    <a ngbNavLink>Komplettering</a>
    <ng-template ngbNavContent> 2 </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
