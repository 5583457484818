import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FeedbackMessage } from 'src/models/feedback-msg';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private feedBackMsg = new BehaviorSubject<FeedbackMessage>(null);
  public $feedBackMsg = this.feedBackMsg.asObservable();

  constructor() {}

  public showMessage(msg: FeedbackMessage) {
    this.feedBackMsg.next(msg);
  }

  public clearMessage() {
    this.feedBackMsg.next(null);
  }
}
