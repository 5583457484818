import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivitySubmission } from 'src/models/course-activity';
import { CourseActivityGrade } from 'src/models/course-grades.ts/course-activity-grade';

@Component({
  selector: 'app-submission-feedback',
  templateUrl: './submission-feedback.component.html',
  styleUrls: ['./submission-feedback.component.scss'],
})
export class SubmissionFeedbackComponent implements OnInit {
  @Input() activitySubmissions: ActivitySubmission[];
  @Input() assignmentResult: CourseActivityGrade;
  headers = ['Händelse', 'Kommentar', 'Fil / Betyg'];
  apiBase = environment.api;

  constructor() {}

  ngOnInit(): void {}
}
