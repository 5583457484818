<table mdbTable class="grades" [class.without-outlines]="!outlined">
  <thead>
    <tr class="mark-top mark-bottom">
      <th class="grade-header">
        <span class="text">Betyg</span>
      </th>
      <th class="grade-header">
        <span class="text">Datum</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td
        [attr.rowspan]="grade?.criterias?.length"
        class="grade-label-container mark-bottom"
      >
        <app-result-label
          [grade]="result"
          [marked]="result"
          [grow]="true"
        ></app-result-label>
      </td>

      <td class="criteria-status mark-bottom">
        {{ date | date: 'dd-MM-yyyy HH:mm' }}
      </td>
    </tr>
  </tbody>
</table>

<table
  mdbTable
  class="feedback"
  [class.without-outlines]="!outlined"
  [hidden]="!comment"
>
  <thead>
    <tr>
      <th>Kommentar</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td [innerHTML]="comment"></td>
    </tr>
  </tbody>
</table>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Vad betyder poängen?</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Poäng ges för varje kriterium. För att få godkänt på en modul krävs att
      samtliga kriterium är uppfyllda och i sin tur måste samtliga poäng samlas
      in.
    </p>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      color="#3db61fc4"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-shield-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M5.072.56C6.157.265 7.31 0 8 0s1.843.265 2.928.56c1.11.3 2.229.655 2.887.87a1.54 1.54 0 0 1 1.044 1.262c.596 4.477-.787 7.795-2.465 9.99a11.775 11.775 0 0 1-2.517 2.453 7.159 7.159 0 0 1-1.048.625c-.28.132-.581.24-.829.24s-.548-.108-.829-.24a7.158 7.158 0 0 1-1.048-.625 11.777 11.777 0 0 1-2.517-2.453C1.928 10.487.545 7.169 1.141 2.692A1.54 1.54 0 0 1 2.185 1.43 62.456 62.456 0 0 1 5.072.56z"
      />
    </svg>
    = Mästarpoäng
    <br />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      color="#3db61fc4"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-star-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"
      />
    </svg>
    = Kurspoäng
    <br />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      color="#3db61fc4"
      width="16"
      height="16"
      fill="currentColor"
      class="bi bi-puzzle-fill"
      viewBox="0 0 16 16"
    >
      <path
        d="M3.112 3.645A1.5 1.5 0 0 1 4.605 2H7a.5.5 0 0 1 .5.5v.382c0 .696-.497 1.182-.872 1.469a.459.459 0 0 0-.115.118.113.113 0 0 0-.012.025L6.5 4.5v.003l.003.01c.004.01.014.028.036.053a.86.86 0 0 0 .27.194C7.09 4.9 7.51 5 8 5c.492 0 .912-.1 1.19-.24a.86.86 0 0 0 .271-.194.213.213 0 0 0 .036-.054l.003-.01v-.008a.112.112 0 0 0-.012-.025.459.459 0 0 0-.115-.118c-.375-.287-.872-.773-.872-1.469V2.5A.5.5 0 0 1 9 2h2.395a1.5 1.5 0 0 1 1.493 1.645L12.645 6.5h.237c.195 0 .42-.147.675-.48.21-.274.528-.52.943-.52.568 0 .947.447 1.154.862C15.877 6.807 16 7.387 16 8s-.123 1.193-.346 1.638c-.207.415-.586.862-1.154.862-.415 0-.733-.246-.943-.52-.255-.333-.48-.48-.675-.48h-.237l.243 2.855A1.5 1.5 0 0 1 11.395 14H9a.5.5 0 0 1-.5-.5v-.382c0-.696.497-1.182.872-1.469a.459.459 0 0 0 .115-.118.113.113 0 0 0 .012-.025L9.5 11.5v-.003l-.003-.01a.214.214 0 0 0-.036-.053.859.859 0 0 0-.27-.194C8.91 11.1 8.49 11 8 11c-.491 0-.912.1-1.19.24a.859.859 0 0 0-.271.194.214.214 0 0 0-.036.054l-.003.01v.002l.001.006a.113.113 0 0 0 .012.025c.016.027.05.068.115.118.375.287.872.773.872 1.469v.382a.5.5 0 0 1-.5.5H4.605a1.5 1.5 0 0 1-1.493-1.645L3.356 9.5h-.238c-.195 0-.42.147-.675.48-.21.274-.528.52-.943.52-.568 0-.947-.447-1.154-.862C.123 9.193 0 8.613 0 8s.123-1.193.346-1.638C.553 5.947.932 5.5 1.5 5.5c.415 0 .733.246.943.52.255.333.48.48.675.48h.238l-.244-2.855z"
      />
    </svg>
    = Ordningspoäng
  </div>
</ng-template>
