import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

// Keep in sync with backend and admin-frontend
export type AvailableFeatureFlags = 'ADMIN_MY_STUDENTS' | 'ADMIN_COURSE_SETTINGS';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  flags: string[] = [];

  constructor(private http: HttpClient) { }

  loadConfig() {
    return new Promise<void>((resolve) => {
      this.http
        .get<string[]>(`${environment.api}/user/feature-flags`)
        .subscribe(
          (data) => {
            this.flags = data;
            resolve();
          },
          (err) => {
            this.flags = [];
            resolve();
          }
        );
    });
  }

  isFeatureEnabled(key: AvailableFeatureFlags) {
    return this.flags.includes(key);
  }
}
