import { Component, Input, OnInit } from '@angular/core';
import { CourseActivityGrade } from 'src/models/course-grades.ts/course-activity-grade';

@Component({
  selector: 'app-grade-criterias-description-wrapper',
  templateUrl: './grade-criterias-description-wrapper.component.html',
  styleUrls: ['./grade-criterias-description-wrapper.component.scss'],
})
export class GradeCriteriasDescriptionWrapperComponent implements OnInit {
  private static readonly GRADE_DEFINITION_SPLIT_STR = '&&break&&';

  @Input() set grade(grade: CourseActivityGrade) {
    this.grade_ = grade;
    this.updateDescriptionTexts();
  }

  grade_: CourseActivityGrade;

  firstGradeDescription: string;
  secondGradeDescription: string;
  constructor() {}

  ngOnInit(): void {}

  private updateDescriptionTexts() {
    let splitIndex = this.grade_?.gradeDescription?.indexOf(
      GradeCriteriasDescriptionWrapperComponent.GRADE_DEFINITION_SPLIT_STR
    );

    if (splitIndex > 0) {
      this.firstGradeDescription = this.grade_.gradeDescription.substr(
        0,
        splitIndex
      );
      this.secondGradeDescription = this.grade_.gradeDescription.substr(
        splitIndex +
          GradeCriteriasDescriptionWrapperComponent.GRADE_DEFINITION_SPLIT_STR
            .length
      );
    } else {
      this.firstGradeDescription = this.grade_?.gradeDescription;
      this.secondGradeDescription = '';
    }
  }
}
