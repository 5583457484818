import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { WritingPart } from 'src/models/course-writing/writing-part';
import { WritingAssignmentService } from 'src/app/services/writing-assignment.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CourseAssignmentQuestionAnswer } from 'src/models/course-assignmnent-question-answer';
import * as _ from 'lodash';
import { CourseDataService } from 'src/app/services/course-data.service';
import { StoredAssignmentAnswers } from 'src/models/stored-assignment-answers';
import { ToastService } from 'src/app/services/toast.service';
import { tap } from 'rxjs/operators';
import { ActivitySubmission } from 'src/models/course-activity';
import { environment } from 'src/environments/environment';

@UntilDestroy()
@Component({
  selector: 'app-assignment-form',
  templateUrl: './assignment-form.component.html',
  styleUrls: ['./assignment-form.component.scss'],
})
export class AssignmentFormComponent implements OnInit {
  private static readonly SAVE_INIT_MSG = 'Sparar uppgift.';
  private static readonly SAVE_SUCCESS_MSG = 'Uppgiften har sparats.';
  private static readonly SAVE_ERROR_MSG = 'Ett fel inträffade vid sparning.';

  private static readonly SUBMIT_INIT_MSG = 'Sparar och lämnar in uppgift.';
  private static readonly SUBMIT_SUCCESS_MSG = 'Uppgiften har lämnats in.';
  private static readonly SUBMIT_ERROR_MSG =
    'Ett fel inträffade vid inlämning.';

  @Input() completedFetchingAnswers = false;
  @Input() activityId: number;
  @Input() latestSubmission: ActivitySubmission;
  @Input() grade: number;

  @Input() set storedAnswers(storedAnswers: StoredAssignmentAnswers) {
    this._storedAnswers = storedAnswers;
    this.updateAnswerInputValues();
  }

  apiBase = environment.api;

  writingPart: WritingPart;
  wordCount: number;
  wordCountMandatory: number;

  assignmentForm: FormGroup;
  formQuestions: FormGroup;
  commentControl: FormControl;

  _storedAnswers: StoredAssignmentAnswers;

  closeResult = '';

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private writingService: WritingAssignmentService,
    private courseDataService: CourseDataService,
    private toastService: ToastService
  ) {
    this.commentControl = new FormControl('');
    this.assignmentForm = this.formBuilder.group({
      comment: this.commentControl,
      questionInputs: new FormGroup({}),
    });

    this.clearInputs();
  }

  ngOnInit() {
    this.writingService.$writingPart
      .pipe(untilDestroyed(this))
      .subscribe((res) => this.setWritingPart(res));
  }

  //open modal for submission, warns user before submitting
  open(content: any) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  saveResults() {
    let toast = this.toastService.show(AssignmentFormComponent.SAVE_INIT_MSG);
    this.storeAnswers(false)
      .pipe(tap((res) => this.toastService.remove(toast)))
      .subscribe(
        (res) => {
          this.toastService.showSuccess(
            AssignmentFormComponent.SAVE_SUCCESS_MSG
          );
        },
        (err) => {
          this.toastService.showDanger(AssignmentFormComponent.SAVE_ERROR_MSG);
        }
      );
  }

  onModalSubmit(modal) {
    let toast = this.toastService.show(AssignmentFormComponent.SUBMIT_INIT_MSG);
    this.storeAnswers(true)
      .pipe(tap((res) => this.toastService.remove(toast)))
      .subscribe(
        (res) => {
          this.toastService.showSuccess(
            AssignmentFormComponent.SUBMIT_SUCCESS_MSG
          );
          modal.close('Save click');
        },
        (err) => {
          this.toastService.showDanger(
            AssignmentFormComponent.SUBMIT_ERROR_MSG
          );
        }
      );
  }

  wordCountUpdate() {
    this.wordCount = 0;
    this.wordCountMandatory = 0;
    this.writingPart?.questions.forEach((question) => {
      const text = this.formQuestions.value[question.id];
      this.wordCount += text ? text.split(/\s+/).length : 0;

      if (question.required && question.id <= 3) {
        this.wordCountMandatory += text ? text.split(/\s+/).length : 0;
      }
    });
  }

  private storeAnswers(submit: boolean) {
    let answers: CourseAssignmentQuestionAnswer[] = [];
    _.forOwn(this.formQuestions.value, (value, key) => {
      answers.push({
        questionId: +key,
        text: value,
      });
    });

    let comment = this.assignmentForm.value.comment;

    return this.courseDataService.storeAcitvitySubmissionAnswers(
      this.activityId,
      { submit, answers, comment }
    );
  }

  private setWritingPart(writingPart: WritingPart) {
    this.writingPart = writingPart;

    this.clearInputs();

    this.writingPart?.questions.forEach((question) => {
      this.formQuestions.addControl(
        question.id.toString(),
        new FormControl('')
      );
    });

    this.updateAnswerInputValues();
  }

  private updateAnswerInputValues() {
    if (this.writingPart && this._storedAnswers) {
      _.forEach(this._storedAnswers.answers, (answer) => {
        let formControl = this.formQuestions.get(answer.questionId.toString());
        if (formControl) {
          formControl.setValue(answer.text);

          this.commentControl.setValue(this._storedAnswers.comment);
        }
      });

      this.wordCountUpdate();
    }
  }

  private clearInputs() {
    this.commentControl.setValue('');
    this.formQuestions = this.formBuilder.group({});
    this.assignmentForm.setControl('questionInputs', this.formQuestions);
  }
}
