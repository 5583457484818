<div>
  <button *ngIf="!isRegistred" class="btn btn-link btn-sm" (click)="register()">
    <i class="bi-person-plus"></i>
    Anmäl mig
  </button>
  <button
    *ngIf="isRegistred"
    class="btn btn-link btn-sm"
    (click)="unregister()"
  >
    <i class="bi-person-dash"></i>
    Avanmäl mig
  </button>
</div>
