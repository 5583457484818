import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

const GRADE_LABELS = ['U', 'K', 'G'];

@Pipe({
  name: 'gradeLabel',
  pure: true,
})
export class GradeLabelPipe implements PipeTransform {
  transform(value: number): string {
    if (value === undefined || value === null) {
      return '-';
    }

    const label = _.nth(GRADE_LABELS, value);

    if (label) {
      return label;
    }

    return String(value);
  }
}
