import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { CourseDataService } from 'src/app/services/course-data.service';
import { CoursePageService } from 'src/app/services/course-page.service';
import { CourseHelper } from 'src/helpers/course-helper';
import { Course } from 'src/models/course';
import { CourseActivityIndex } from 'src/models/course-activity-index';
import { CoursePart } from 'src/models/course-part';
import { CoursePointsData } from 'src/models/course-points';

@UntilDestroy()
@Component({
  selector: 'app-course-items',
  templateUrl: './course-items.component.html',
  styleUrls: ['./course-items.component.scss'],
})
export class CourseItemsComponent implements OnInit {
  readonly staticCourseParts = CoursePageService.STATIC_COURSE_PARTS;

  course: Course = null;
  curActivityId: number;
  activeCoursePartIndex = -1;
  coursePointsData: CoursePointsData = {
    courseId: 0,
    coursePoints: {
      master: { available: 0, earned: 0 },
      course: { available: 0, earned: 0 },
      order: { available: 0, earned: 0 },
    },
    parts: [],
  };

  //See CourseActivityType enum for type indexes
  activityTypeIconMappings = [
    { icon: 'camera-reels', name: 'Video' },
    { icon: 'pencil-square', name: 'Skrivuppgift' },
    { icon: 'book', name: 'Läsuppgift' },
    { icon: 'question-square', name: 'Quiz' },
    { icon: 'chat-quote', name: 'Seminarium' },
    { icon: 'ui-checks', name: 'Inlämningsuppgift' },
  ];

  partsFinished: boolean[];
  collapsed: boolean[];
  show = false;
  progress = 0;

  //@Output() progessEvent = new EventEmitter<Number>();
  activityCount: number;

  constructor(
    private coursePageService: CoursePageService,
    private courseDataService: CourseDataService
  ) {}

  ngOnInit(): void {
    this.coursePageService.$curActivityId
      .pipe(untilDestroyed(this))
      .subscribe((activityIndex) => this.setActivityId(activityIndex));

    this.courseDataService.$course
      .pipe(untilDestroyed(this))
      .subscribe((course) => {
        if (course) {
          this.setCourse(course);
          this.getCoursePointsData(course);
        }
      });
  }

  toggleShow() {
    this.show = !this.show;
  }

  hide() {
    this.show = false;
  }

  toggleCoursePart(index: number) {
    this.collapsed[index] = !this.collapsed[index];
  }

  selectActivity(id: number) {
    this.coursePageService.setActivityId(id);
    this.hide();
  }

  onCourseActivityChange() {
    if (this.curActivityId >= 0) {
      this.activeCoursePartIndex = CourseHelper.getPartIndexForActivityId(
        this.curActivityId,
        this.course
      );

      if (this.activeCoursePartIndex >= 0) {
        this.collapsed[this.activeCoursePartIndex] = false;
      }
    } else {
      this.activeCoursePartIndex = -1;
    }
  }

  private setCourse(course: Course) {
    this.course = course;

    let arrayLen = 0;

    if (course?.parts?.length) {
      arrayLen = course.parts.length;
    }

    this.collapsed = Array(arrayLen).fill(false);
    this.partsFinished = Array(arrayLen).fill(false);

    this.updateCourseProgress(course);
    this.onCourseActivityChange();
  }

  private updateCourseProgress(course: Course) {
    this.partsFinished.forEach((el, index) =>
      this.coursePartFinishedUpdate(index)
    );

    this.progress = course ? course.progress : 0;
  }

  private coursePartFinishedUpdate(partIndex: number) {
    let foundUnfinished = false;
    _.forEach(this.course?.parts[partIndex].activities, (activity) => {
      if (!activity.finished) {
        foundUnfinished = true;
        return false;
      }
    });

    this.partsFinished[partIndex] = !foundUnfinished;
  }

  private setActivityId(id: number) {
    this.curActivityId = id;
    this.onCourseActivityChange();
  }

  private getCoursePointsData(course: Course) {
    this.courseDataService.getCoursePointsData(course?.id).subscribe((res) => {
      this.coursePointsData = res;
    });
  }

  public getTrueCount(array) {
    var count = 0;
    for (var i = 0; i < array.length; i++) {
      if (array[i].finished) {
        count++;
      }
    }
    return count;
  }
}
