import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];

  constructor() {}

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    let toastObj = { textOrTpl, ...options };
    this.toasts.push(toastObj);
    return toastObj;
  }

  showSuccess(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    return this.show(textOrTpl, {
      classname: 'bg-success text-light',
      ...options,
    });
  }

  showDanger(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    return this.show(textOrTpl, {
      classname: 'bg-danger text-light',
      ...options,
    });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }
}
