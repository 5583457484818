<nav
  ngbNav
  #nav="ngbNav"
  [(activeId)]="activeTab"
  class="responsive-nav nav-tabs"
  (navChange)="onTabChange($event)"
>
  <ng-container [ngbNavItem]="1">
    <a ngbNavLink>
      <span>{{ tabTitles[0] }}</span>
      <i class="bi-card-text"></i>
    </a>

    <ng-template ngbNavContent>
      <div>
        <h3>{{ writingPart?.title }}</h3>
        <p [innerHTML]="writingPart?.description"></p>
      </div>
      <app-activity-point-overview
        *ngIf="activityPointsData?.criterias?.length != 0"
        [activityCriterias]="activityPointsData.criterias"
        [pointSummary]="activityPointsData.pointSummary"
        [withResult]="false"
      >
      </app-activity-point-overview>
    </ng-template>
  </ng-container>

  <ng-container [ngbNavItem]="2">
    <a ngbNavLink>
      <span>{{ tabTitles[1] }}</span>
      <i class="bi-pen"></i>
    </a>

    <ng-template ngbNavContent>
      <app-assignment-form
        [completedFetchingAnswers]="fetchedAnswers"
        [storedAnswers]="savedAnswers"
        [activityId]="activityId"
        [grade]="assignmentResult?.grade"
        [latestSubmission]="latestSubmission"
      ></app-assignment-form>
    </ng-template>
  </ng-container>

  <ng-container [ngbNavItem]="3">
    <a ngbNavLink>
      <span>{{ tabTitles[2] }}</span>
      <i class="bi-award"></i>
    </a>

    <ng-template ngbNavContent>
      <app-grade-criterias-description-wrapper
        [grade]="assignmentResult"
      ></app-grade-criterias-description-wrapper>
      <app-activity-point-overview
        *ngIf="activityPointsData?.criterias?.length != 0"
        [activityCriterias]="activityPointsData.criterias"
        [pointSummary]="activityPointsData.pointSummary"
        [withResult]="true"
      >
      </app-activity-point-overview>
    </ng-template>
  </ng-container>

  <ng-container [ngbNavItem]="4">
    <a ngbNavLink>
      <span>{{ tabTitles[3] }}</span>
      <i class="bi-clock-history"></i>
    </a>

    <ng-template ngbNavContent>
      <app-submission-feedback
        [activitySubmissions]="activitySubmissions"
        [assignmentResult]="assignmentResult"
      ></app-submission-feedback>
    </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav" class="nav-content"></div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Profile update</h4>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="modal.dismiss('Cross click')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="dateOfBirth">Date of birth</label>
        <div class="input-group">
          <input
            id="dateOfBirth"
            class="form-control"
            placeholder="yyyy-mm-dd"
            name="dp"
            ngbDatepicker
            #dp="ngbDatepicker"
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary calendar"
              (click)="dp.toggle()"
              type="button"
            ></button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-outline-dark"
      (click)="modal.close('Save click')"
    >
      Save
    </button>
  </div>
</ng-template>
