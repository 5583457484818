<p *ngIf="activitySubmissions.length === 0">
  Inga inlämningar har gjorts än så länge
</p>
<table mdbTable *ngIf="activitySubmissions.length !== 0">
  <thead>
    <tr>
      <th *ngFor="let head of headers">
        {{ head }}
      </th>
    </tr>
  </thead>
  <tbody>
    <tr mdbTableCol *ngFor="let activitySubmission of activitySubmissions">
      <td>
        Inlämnad
        <br />
        {{ activitySubmission.date | date: 'dd-MM-yyyy HH:mm' }}
      </td>
      <td>Version {{ activitySubmission.version }}</td>
      <td>
        <a
          class="filename-container"
          [href]="
            apiBase +
            '/course/activity/submission/' +
            activitySubmission?.documentRetrievalToken +
            '/pdf'
          "
          target="_blank"
          color="primary"
          ><i class="bi bi-filetype-pdf"></i> PDF</a
        >
      </td>
    </tr>
  </tbody>
  <tfoot *ngIf="assignmentResult">
    <tr>
      <td>
        Rättad
        <br />
        {{ assignmentResult.date | date: 'dd-MM-yyyy HH:mm' }} av
        <a
          [href]="'https://liu.se/medarbetare/' + assignmentResult?.admin.liuId"
          target="_blank"
          [innerHTML]="'\<br>' + assignmentResult?.admin.liuId"
        >
        </a>
      </td>

      <td [innerHTML]="assignmentResult.feedback"></td>

      <td>
        <div class="grade-container">
          <app-result-label
            [grade]="assignmentResult.grade"
            [marked]="true"
          ></app-result-label>
        </div>
      </td>
    </tr>
  </tfoot>
</table>
