<div class="progressContainer">
  <div class="container">Andel av kursen avklarad:</div>
  <div class="container">
    <ngb-progressbar
      [showValue]="true"
      type="success"
      [value]="progress === 0 ? 0 : progress"
    ></ngb-progressbar>
  </div>

  <div class="pointsContainer marginTop">
    <div class="container">Poängsammanställning:</div>

    <div class="point" ngbTooltip="Kurspoäng">
      {{
        coursePointsProgress?.course.earned +
          '/' +
          coursePointsProgress?.course.available
      }}
      <i
        class="bi bi-star-fill icon"
        [ngClass]="{
          bronze:
            coursePointsProgress?.course.earned /
              coursePointsProgress?.course.available >
              0 &&
            coursePointsProgress?.course.earned /
              coursePointsProgress?.course.available <=
              0.3,
          silver:
            coursePointsProgress?.course.earned /
              coursePointsProgress?.course.available >
              0.3 &&
            coursePointsProgress?.course.earned /
              coursePointsProgress?.course.available <=
              0.7,
          gold:
            coursePointsProgress?.course.earned /
              coursePointsProgress?.course.available >
            0.7
        }"
      ></i>
    </div>
    <div class="point" ngbTooltip="Mästarpoäng">
      {{
        coursePointsProgress?.master.earned +
          '/' +
          coursePointsProgress?.master.available
      }}
      <i
        class="bi bi-shield-fill icon"
        [ngClass]="{
          bronze:
            coursePointsProgress?.master.earned /
              coursePointsProgress?.master.available >
              0 &&
            coursePointsProgress?.master.earned /
              coursePointsProgress?.master.available <=
              0.3,
          silver:
            coursePointsProgress?.master.earned /
              coursePointsProgress?.master.available >
              0.3 &&
            coursePointsProgress?.master.earned /
              coursePointsProgress?.master.available <=
              0.7,
          gold:
            coursePointsProgress?.master.earned /
              coursePointsProgress?.master.available >
            0.7
        }"
      ></i>
    </div>

    <div class="point" ngbTooltip="Ordningspoäng">
      {{
        coursePointsProgress?.order.earned +
          '/' +
          coursePointsProgress?.order.available
      }}
      <i
        class="bi bi-puzzle-fill icon"
        [ngClass]="{
          bronze:
            coursePointsProgress?.order.earned /
              coursePointsProgress?.order.available >
              0 &&
            coursePointsProgress?.order.earned /
              coursePointsProgress?.order.available <=
              0.3,
          silver:
            coursePointsProgress?.order.earned /
              coursePointsProgress?.order.available >
              0.3 &&
            coursePointsProgress?.order.earned /
              coursePointsProgress?.order.available <=
              0.7,
          gold:
            coursePointsProgress?.order.earned /
              coursePointsProgress?.order.available >
            0.7
        }"
      >
      </i>
    </div>
  </div>
</div>
