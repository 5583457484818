import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LogInService {
  public static readonly OPEN_DSA_BASE_URL =
    'https://www.ida.liu.se/opendsa/server/score/';
  public static readonly OPEN_DSA_AUTH_URL =
    LogInService.OPEN_DSA_BASE_URL + 'check_login/';
  public static readonly OPEN_DSA_LOGIN_URL =
    LogInService.OPEN_DSA_BASE_URL + 'login_redirect/';
  public static readonly API = environment.api;

  constructor(private http: HttpClient, private window: Window) {}

  login() {
    console.log('Logging in');
    this.http
      .post<any>(
        `${environment.api}/saml/login-request?next=http://google.com`,
        {}
      )
      .subscribe((res) => console.log(res));
  }

  redirectToLiuLogin() {
    var data = {
      post_key: true,
      current_url: `http://${this.window.location.hostname}:5000/api/auth/handleLiuSignIn`,
      score_url: LogInService.OPEN_DSA_BASE_URL,
    };

    this.http
      .post<any>(LogInService.OPEN_DSA_LOGIN_URL, data, {
        headers: {
          'content-type': 'application/json',
        },
      })
      .subscribe((res) => {
        window.location.href = res.alt_url;
      });
  }
}
