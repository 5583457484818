import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CourseDataService } from 'src/app/services/course-data.service';
import { CoursePageService } from 'src/app/services/course-page.service';
import { NotificationsDataService } from 'src/app/services/notifications-data.service';
import { CourseSeminarActivityData } from 'src/models/course-activity-data/course-seminar-activity-data';
import { CourseActivityGrade } from 'src/models/course-grades.ts/course-activity-grade';
import { Grade } from 'src/enums/grade';
import { StoredAssignmentAnswers } from 'src/models/stored-assignment-answers';
import { GeneralCourseActivityData } from 'src/models/course-activity-data/general-course-activity-data';
import { CourseActivityType } from 'src/enums/course-activity.enum';
import { CourseActivityPointDescription } from 'src/models/course-activity-points';

@UntilDestroy()
@Component({
  selector: 'app-seminar',
  templateUrl: './seminar.component.html',
  styleUrls: ['./seminar.component.scss'],
})
export class SeminarComponent implements OnInit {
  @Input() seminar: CourseSeminarActivityData;

  private static readonly RESULTS_TAB_INDEX = 2;

  activeTabIndex = 1;
  activityPointsData = {
    criterias: [],
    pointSummary: {},
  };

  // result: CourseActivityGrade;
  result: CourseActivityGrade;
  grade = Grade;
  activityId: number;

  storedAnswers: StoredAssignmentAnswers;

  constructor(
    private coursePageService: CoursePageService,
    private courseDataService: CourseDataService,
    private notificationsService: NotificationsDataService
  ) {}

  ngOnInit(): void {
    this.coursePageService.$showResult
      .pipe(untilDestroyed(this))
      .subscribe(
        () => (this.activeTabIndex = SeminarComponent.RESULTS_TAB_INDEX)
      );

    this.coursePageService.$curActivityId.subscribe((activityId) => {
      this.activityId = activityId;
      this.courseDataService.getActivityResult(activityId).subscribe((res) => {
        this.result = res;
      });
      this.courseDataService.getActivityPoint(activityId).subscribe((res) => {
        this.activityPointsData = res;
      });
    });

    this.coursePageService.$curActivity.subscribe((activity) => {
      if (activity === null) {
        return;
      }
      if (activity.type === CourseActivityType.seminar) {
        this.courseDataService
          .getActivitySubmissionAnswers(activity.id)
          .subscribe((res) => {
            this.storedAnswers = res;
          });
      }
    });
  }

  setActivityData(activityData: GeneralCourseActivityData) {
    if (activityData.type === CourseActivityType.seminar) {
    }
  }

  onTabChange(event) {
    if (!this.seminar) return;

    if (event.nextId == SeminarComponent.RESULTS_TAB_INDEX) {
      // this.notificationsService.markGradesRead([this.seminar?.content.seminarGrade.gradingId]).subscribe()
    }
  }
}
