<div class="container-fluid d-flex justify-content-center pt-4">
  <div class="card" style="width: 18rem">
    <div class="card-body">
      <h5 class="card-title">E-prof</h5>
      <h6 class="card-subtitle mb-2 text-muted">
        Klicka på knappen nedan för att logga in
      </h6>
      <button
        type="button"
        class="btn btn-primary btn-lg"
        (click)="handleLoginClick()"
      >
        Logga in med LiU-ID
      </button>
    </div>
  </div>
</div>
