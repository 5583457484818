<div
  class="grade-label-box"
  [ngClass]="marked || isCriteria ? (grade | gradeKey) : ''"
  [class.grow]="grow"
  [class.grade]="!isCriteria"
  placement="top"
  [ngbTooltip]="statusDescriptions[grade | gradeKey]"
>
  <ng-container *ngIf="isCriteria">
    <i
      *ngIf="grade !== undefined"
      [class]="
        (grade | gradeKey) === 'passed' ? 'bi-check-circle-fill' : 'bi-circle-fill'
      "
    ></i>

    <i *ngIf="grade === undefined" class="bi-circle"></i>
  </ng-container>

  <div
    class="character"
    [class.for-icon]="isCriteria"
    *ngIf="
      grade !== undefined && (!isCriteria || (grade | gradeKey) !== 'passed')
    "
  >
    {{ grade | gradeLabel }}
  </div>
</div>
