import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CourseDataService } from 'src/app/services/course-data.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { CourseOverview } from 'src/models/course-overview';
import { AuthResult } from 'src/models/auth';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss'],
})
export class AccountPageComponent implements OnInit {
  private static readonly TITLE = { defaultTitle: 'Min profil' };

  private loadComp = false;

  courseOverviews: CourseOverview[];
  //authResult: AuthResult[];private authService: AuthService,

  constructor(
    private toolbar: ToolbarService,
    private courseDataService: CourseDataService
  ) {}

  ngOnInit(): void {
    this.toolbar.setTitle(AccountPageComponent.TITLE);

    this.courseDataService.$courses
      .pipe(untilDestroyed(this))
      .subscribe((overviews) => (this.courseOverviews = overviews));

    this.courseDataService.getCourseOverviews().subscribe();
  }
}
