import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { BehaviorSubject, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NotificationType } from 'src/enums/notification-type.enum';
import { environment } from 'src/environments/environment';
import { ActivityReminderNotification } from 'src/models/notification/activity-reminder-notification';
import { GeneralNotification } from 'src/models/notification/general-notification';
import { GradeNotification } from 'src/models/notification/grade-notification';
import { NewsNotification } from 'src/models/notification/news-notification';
import { GroupReminderNotification } from 'src/models/notification/group-reminder-notification';
import { ActivityResultNotification } from 'src/models/notification/activity-result-notification';
import { CourseResultNotification } from 'src/models/notification/course-result-notification';

@Injectable({
  providedIn: 'root',
})
export class NotificationsDataService {
  private notifications: GeneralNotification[];
  private notificationsSubject = new BehaviorSubject<GeneralNotification[]>(
    null
  );
  public $notifications = this.notificationsSubject.asObservable();

  constructor(private http: HttpClient) {}

  public getNotifications() {
    return this.http
      .get<GeneralNotification[]>(`${environment.api}/user/notifications`)
      .pipe(tap((res) => this.setNotifications(res)));
  }

  public clearLocalNotifications() {
    this.notificationsSubject.next([]);
  }

  public clearCourseNewsNotifications(courseId: number) {
    let newInfoForCourse = false;
    _.forEach(this.notifications, (notification) => {
      if (notification.type == NotificationType.courseNews) {
        notification = notification as NewsNotification;
        if (notification.courseId == courseId) {
          newInfoForCourse = true;
          return false;
        }
      }
    });

    if (!newInfoForCourse) {
      return of();
    }

    return this.http
      .delete<number>(`${environment.api}/course/${courseId}/news/mark_read`)
      .pipe(
        tap((result) => {
          if (!(result > 0)) return;

          const newNotifications = _.filter(
            this.notifications,
            (notification) => {
              if (notification.type !== NotificationType.courseNews) {
                return true;
              } else {
                const newsNotification = notification as NewsNotification;
                return newsNotification.courseId !== courseId;
              }
            }
          );

          this.setNotifications(newNotifications);
        })
      );
  }

  public markGroupDeadlineRead(collectionId: number) {
    let notificationExists = false;
    _.forEach(this.notifications, (notification) => {
      if (notification.type == NotificationType.groupReminder) {
        notification = notification as GroupReminderNotification;
        if (notification.collectionId == collectionId) {
          notificationExists = true;
          return false;
        }
      }
    });

    if (notificationExists) {
      return this.http
        .delete<boolean>(
          `${environment.api}/course/mark_group_deadline_read/${collectionId}`
        )
        .pipe(
          tap((result) => {
            if (result) {
              this.removeGroupDeadlineNotification(collectionId);
            }
          })
        );
    }

    return of();
  }

  public markActivityDeadlineRead(activityId: number) {
    return this.http
      .delete(
        `${environment.api}/course/activity/${activityId}/mark_deadline_read`
      )
      .pipe(tap(() => this.removeActivityDeadlineNotification(activityId)));
  }

  public markActivityResultRead(activityResultId: number) {
    return this.http
      .put(
        `${environment.api}/course/activity/${activityResultId}/mark_activity_result_read`,
        {}
      )
      .pipe(tap(() => this.removeActivityResultNotification(activityResultId)));
  }

  public markCourseResultRead(courseResultId: number) {
    return this.http
      .put(
        `${environment.api}/course/${courseResultId}/mark_course_result_read`,
        {}
      )
      .pipe(tap(() => this.removeCourseResultNotification(courseResultId)));
  }

  public markGradesRead(gradingIds: number[]) {
    const matchedUnreadGradingIds = _.filter(gradingIds, (id) => {
      return _.find(this.notifications, (notification) => {
        return (
          notification.type === NotificationType.grade &&
          (notification as GradeNotification).id == id
        );
      });
    }) as number[];

    if (!matchedUnreadGradingIds.length) {
      return of();
    }

    return this.http
      .post(
        `${environment.api}/course/activity/grade/mark_grades_read`,
        matchedUnreadGradingIds
      )
      .pipe(
        tap(() => this.removeGradingNotifications(matchedUnreadGradingIds))
      );
  }

  private removeGroupDeadlineNotification(collectionId: number) {
    const newNotifications = _.filter(this.notifications, (notification) => {
      if (notification.type !== NotificationType.groupReminder) {
        return true;
      } else {
        const groupReminderNotification =
          notification as GroupReminderNotification;
        if (groupReminderNotification.collectionId !== collectionId) {
          return true;
        }
        return false;
      }
    });
    this.setNotifications(newNotifications);
  }

  private removeActivityDeadlineNotification(activityId: number) {
    const newNotifications = _.filter(this.notifications, (notification) => {
      if (notification.type !== NotificationType.activityReminder) {
        return true;
      } else {
        const activityReminderNotification =
          notification as ActivityReminderNotification;
        if (activityReminderNotification.activityId !== activityId) {
          return true;
        }
        return false;
      }
    });
    this.setNotifications(newNotifications);
  }

  private removeActivityResultNotification(activityResultId: number) {
    const newNotifications = _.filter(this.notifications, (notification) => {
      if (notification.type !== NotificationType.activityResult) {
        return true;
      } else {
        const activityResultNotification =
          notification as ActivityResultNotification;
        if (activityResultNotification.id !== activityResultId) {
          return true;
        }
        return false;
      }
    });
    this.setNotifications(newNotifications);
  }

  private removeCourseResultNotification(courseResultId: number) {
    const newNotifications = _.filter(this.notifications, (notification) => {
      if (notification.type !== NotificationType.courseResult) {
        return true;
      } else {
        const courseResultNotification =
          notification as CourseResultNotification;
        if (courseResultNotification.id !== courseResultId) {
          return true;
        }
        return false;
      }
    });
    this.setNotifications(newNotifications);
  }

  private removeGradingNotifications(gradingIds: number[]) {
    const newNotifications = _.filter(this.notifications, (notification) => {
      if (notification.type !== NotificationType.grade) {
        return true;
      } else {
        const gradeNotification = notification as GradeNotification;
        const toBeDeleted = _.indexOf(gradingIds, gradeNotification.id) >= 0;
        return !toBeDeleted;
      }
    });

    this.setNotifications(newNotifications);
  }

  private setNotifications(notifications: GeneralNotification[]) {
    this.notifications = notifications;
    this.notificationsSubject.next(notifications);
  }
}
