<app-progress-bar [progress]="progress" [includeText]="true"></app-progress-bar>

<button
  class="btn btn-primary"
  [disabled]="!canCheck && !isSummaryPage"
  (click)="onNextClick()"
>
  <span [hidden]="isSummaryPage">
    <span [hidden]="isPostState"> Rätta </span>

    <span [hidden]="!isPostState">
      <span [hidden]="isCorrect"> Försök igen </span>

      <span [hidden]="!isCorrect"> Nästa </span>
    </span>
  </span>

  <span [hidden]="!isSummaryPage" class="go-on-container">
    <span>Gå vidare</span>
    <i class="bi-chevron-right"></i>
  </span>
</button>
