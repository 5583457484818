<ng-container *ngIf="!courseMaterialItem?.expandable">
  <h5 *ngIf="courseMaterialItem?.title?.length">
    {{ courseMaterialItem?.title }}
  </h5>

  <div class="non-expandable-content-container">
    <ng-container *ngTemplateOutlet="itemContent"></ng-container>
  </div>
</ng-container>

<ngb-accordion #acc="ngbAccordion">
  <ngb-panel
    cardClass="ngb-panel-card"
    [title]="courseMaterialItem?.title"
    *ngIf="courseMaterialItem?.expandable"
  >
    <ng-template ngbPanelHeader let-opened="opened">
      <div class="panel-header">
        <button ngbPanelToggle>
          <h5>
            {{ courseMaterialItem?.title }}
          </h5>
          <span
            ><i
              [class]="opened ? 'bi-chevron-up' : 'bi-chevron-down'"
              style="font-size: 24px"
            ></i
          ></span>
        </button>
      </div>
    </ng-template>

    <ng-template ngbPanelContent>
      <div class="panel-content-wrapper">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
      </div>
    </ng-template>
  </ngb-panel>

  <ng-template #itemContent>
    <div class="custom-text" [innerHTML]="courseMaterialItem?.text"></div>

    <app-video-content
      *ngIf="courseMaterialItem?.type == videoType"
      [courseVideo]="courseMaterialItem"
    ></app-video-content>

    <app-image-content
      *ngIf="courseMaterialItem?.type == imageType"
      [imageMaterial]="courseMaterialItem"
    ></app-image-content>

    <app-pdf-content
      *ngIf="courseMaterialItem?.type == pdfType"
      [pdfMaterial]="courseMaterialItem"
    ></app-pdf-content>

    <app-info-card
      *ngIf="courseMaterialItem?.type == cardType"
      [cardItem]="courseMaterialItem"
    ></app-info-card>
  </ng-template>
  <ngb-accordion> </ngb-accordion
></ngb-accordion>
