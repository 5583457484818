import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CourseDataService } from 'src/app/services/course-data.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-group-register-button',
  templateUrl: './group-register-button.component.html',
  styleUrls: ['./group-register-button.component.scss'],
})
export class GroupRegisterButtonComponent implements OnInit {
  @Input() courseCollectionId: number;
  @Input() courseGroupId: number;
  @Input() courseId: number;
  @Input() isRegistred: boolean;
  @Output() registredEvent = new EventEmitter<Object>();
  @Output() unregistredEvent = new EventEmitter<Object>();

  constructor(
    private courseDataService: CourseDataService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {}

  public register() {
    this.courseDataService
      .registerForGroup(
        this.courseId,
        this.courseCollectionId,
        this.courseGroupId
      )
      .subscribe(
        (res) => {
          this.toastService.showSuccess('Successfully joined group');
          this.registredEvent.emit({
            groupId: this.courseGroupId,
            collectionId: this.courseCollectionId,
            name: res.name,
            email: res.email,
            liuId: res.liuId,
          });
        },
        (error) => {
          if (error.status === 409) {
            this.toastService.showDanger(
              'Something went wrong, registration failed'
            );
            this.registredEvent.emit({ groupId: -1 });
          }
        }
      );
  }

  public unregister() {
    this.courseDataService
      .unregisterForGroup(this.courseId, this.courseGroupId)
      .subscribe((res) => {
        this.toastService.showSuccess('Successfully left group');
        this.unregistredEvent.emit({
          groupId: this.courseGroupId,
          collectionId: this.courseCollectionId,
          name: res.name,
          email: res.email,
          liuId: res.liuId,
        });
      });
  }
}
