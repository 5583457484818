import { Component, Input, OnInit } from '@angular/core';
import { GeneralCourseMaterial } from 'src/models/course-material/general-course-material';

@Component({
  selector: 'app-informative-activity',
  templateUrl: './informative-activity.component.html',
  styleUrls: ['./informative-activity.component.scss'],
})
export class InformativeActivityComponent implements OnInit {
  @Input() materials: GeneralCourseMaterial[];

  constructor() {}

  ngOnInit(): void {}
}
