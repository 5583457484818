<div>
  <h4>{{ grade_?.gradeTitle }}</h4>
  <p
    class="result-top-description custom-text"
    [innerHTML]="firstGradeDescription"
  ></p>
</div>

<app-activity-grade-criteria
  [result]="grade_?.grade"
  [grades]="grade_?.grades"
  [comment]="grade_?.feedback"
  [date]="grade_?.date"
></app-activity-grade-criteria>

<p
  class="result-bottom-description custom-text"
  [innerHTML]="secondGradeDescription"
></p>
