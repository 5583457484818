<div class="content-container" [class.notStatic]="activityId >= 0">
  <app-course-content></app-course-content>

  <app-feedback *ngIf="activityId >= 0"></app-feedback>

  <div class="nav-section borders only-borders-top" *ngIf="activityId >= 0">
    <div class="nav-container">
      <app-navigation-controls [hidden]="isQuiz"></app-navigation-controls>

      <app-quiz-nav [hidden]="!isQuiz"></app-quiz-nav>
    </div>
  </div>
</div>

<app-course-items></app-course-items>
