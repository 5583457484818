<div class="content">
  <ng-container>
    <div class="elements">
      <app-course-item
        *ngFor="let material of materials"
        [courseMaterialItem]="material"
      ></app-course-item>
    </div>
  </ng-container>
</div>
