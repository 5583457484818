import * as _ from 'lodash';

export enum Grade {
  failed,
  complementary,
  passed,
  three,
  four,
  five,
}
