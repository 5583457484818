import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { forEach } from 'lodash';
import { CourseDataService } from 'src/app/services/course-data.service';
import { CoursePageService } from 'src/app/services/course-page.service';
import {
  FileSubmission,
  FileUploadService,
} from 'src/app/services/file-upload.service';
import { ToastService } from 'src/app/services/toast.service';
import { CourseActivityType } from 'src/enums/course-activity.enum';
import { CourseSeminarActivityData } from 'src/models/course-activity-data/course-seminar-activity-data';
import { CourseAssignmentSubmitData } from 'src/models/course-assignment-submit-data';
import { CourseAssignmentQuestionAnswer } from 'src/models/course-assignmnent-question-answer';
import { WritingPart } from 'src/models/course-writing/writing-part';
import { StoredAssignmentAnswers } from 'src/models/stored-assignment-answers';

@Component({
  selector: 'app-complementary',
  templateUrl: './complementary.component.html',
  styleUrls: ['./complementary.component.scss'],
})
export class ComplementaryComponent implements OnInit {
  @Input() grade: number;
  activityId: number;
  answers: StoredAssignmentAnswers;
  writingPart: WritingPart;
  private submissionFiles: File[];
  storedFileSubmissions: FileSubmission[];
  wordCount: number;

  complementaryForm: FormGroup = new FormGroup({});
  commentForm: FormControl = new FormControl();

  constructor(
    private dataService: CourseDataService,
    private pageService: CoursePageService,
    private fileUploadService: FileUploadService,
    private modalService: NgbModal,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.pageService.$curActivityId.subscribe((activityId) => {
      this.activityId = activityId;
      this.dataService
        .getActivitySubmissionAnswers(activityId)
        .subscribe((answers) => {
          this.answers = answers;
          this.updateWordCount(answers.answers[0].text);
        });
      this.fileUploadService
        .getSubmissionFiles(this.activityId)
        .subscribe((res) => {
          this.storedFileSubmissions = res;
        });
    });

    //Get writing parts
    this.dataService.$activityData.subscribe((activity) => {
      if (activity === null) {
        return;
      }
      if (activity.type === CourseActivityType.seminar) {
        var writingPart = activity as CourseSeminarActivityData;
        this.writingPart = writingPart.content.complementaryActivity;
        forEach(this.writingPart.questions, (question) => {
          var answer = _.find(this.answers.answers, (answer) => {
            return answer.questionId === question.id;
          });
          this.complementaryForm.addControl(
            question.id.toString(),
            new FormControl(answer ? answer.text : '')
          );
        });
        if (this.writingPart.allowNotes) {
          this.complementaryForm.addControl(
            'comment',
            new FormControl(this.answers.comment ? this.answers.comment : '')
          );
        }
      }
    });
  }

  updateWordCount(text: string) {
    this.wordCount = text.split(/\s+/).length;
  }

  openModal(template: any) {
    this.modalService
      .open(template, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          // console.log(result)
        },
        (reason) => {
          console.log(reason);
        }
      );
  }

  wordCountUpdate() {
    this.wordCount = 0;
    _.forOwn(this.complementaryForm.value, (text, id) => {
      this.updateWordCount(text);
    });
  }

  saveAnswers() {
    this.storeAnswers(false).subscribe((res) => {
      this.toastService.showSuccess('Svar har sparats');
    });
    if (this.submissionFiles.length > 0) {
      forEach(this.submissionFiles, (submissionFile) => {
        this.fileUploadService
          .upload(submissionFile, this.activityId)
          .subscribe((res) => {
            this.toastService.showSuccess(res.filename + ' har laddats upp');
            this.storedFileSubmissions.push(res);
          });
      });
    }
  }

  submitAnswers(modal: any) {
    this.storeAnswers(true).subscribe((res) => {
      this.toastService.showSuccess('Svar har lämnats in');
      modal.close('saved');
    });
    if (this.submissionFiles.length > 0) {
      forEach(this.submissionFiles, (submissionFile) => {
        this.fileUploadService
          .upload(submissionFile, this.activityId)
          .subscribe((res) => {
            this.toastService.showSuccess(res.filename + ' har laddats upp');
            this.storedFileSubmissions.push(res);
          });
      });
    }
  }

  private storeAnswers(submit: boolean) {
    var answers: CourseAssignmentQuestionAnswer[] = [];
    _.forOwn(this.complementaryForm.value, (value, key) => {
      answers.push({
        questionId: +key,
        text: value,
      });
    });

    var comment = '';
    if (this.writingPart.allowNotes) {
      comment = this.complementaryForm.get('comment').value;
    }

    return this.dataService.storeAcitvitySubmissionAnswers(this.activityId, {
      submit,
      answers,
      comment,
    });
  }

  onNewFile(event: any) {
    this.submissionFiles = event.target.files;
  }

  removeFile(fileId: number) {
    this.fileUploadService.deleteSubmissionFile(fileId).subscribe((res) => {
      this.toastService.showSuccess(res.filename + ' har tagits bort');
      _.remove(this.storedFileSubmissions, (fileSubmission) => {
        return fileSubmission.id === res.id;
      });
    });
  }

  downloadFile(activityId: number, filename: string) {
    this.fileUploadService.downloadFile(activityId, filename);
  }
}
