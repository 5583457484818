import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FeedbackService } from 'src/app/services/feedback.service';
import { FeedbackMessage } from 'src/models/feedback-msg';
@UntilDestroy()
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  private static readonly TRANSITION_TIME = 300; //Ms

  hide = true;
  msg: FeedbackMessage;

  constructor(private feedbackService: FeedbackService) {}

  ngOnInit(): void {
    this.feedbackService.$feedBackMsg
      .pipe(untilDestroyed(this))
      .subscribe((msg) => this.setMsg(msg));
  }

  private setMsg(msg: FeedbackMessage | null) {
    if (msg) {
      this.msg = msg;
    } else if (!msg) {
      setTimeout(() => {
        this.msg = null;
      }, FeedbackComponent.TRANSITION_TIME);
    }

    this.hide = msg ? false : true;
  }
}
