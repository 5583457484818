import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CourseDataService } from 'src/app/services/course-data.service';
import { CoursePageService } from 'src/app/services/course-page.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GeneralCourseActivityData } from 'src/models/course-activity-data/general-course-activity-data';
import { CourseActivity } from 'src/models/course-activity';
import { CourseActivityType } from 'src/enums/course-activity.enum';
import { StaticCoursePart } from 'src/models/static-course-part';
import { QuizService } from 'src/app/services/quiz.service';
import { CourseQuizActivityData } from 'src/models/course-activity-data/course-quiz-activity-data';
import { CourseWritingActivityData } from 'src/models/course-activity-data/course-writing-activity-data';
import { WritingAssignmentService } from 'src/app/services/writing-assignment.service';

@UntilDestroy()
@Component({
  selector: 'app-course-content',
  templateUrl: './course-content.component.html',
  styleUrls: ['./course-content.component.scss'],
})
export class CourseContentComponent implements OnInit {
  activityData: GeneralCourseActivityData;

  isCalendar = false;
  isNews = false;
  isInformative = false;
  isQuiz = false;
  isWriting = false;
  isResults = false;
  isSeminar = false;
  isAssignment = false;
  isGroups = false;

  activity: CourseActivity;
  staticPart: StaticCoursePart;

  subject: string = '';

  constructor(
    private courseData: CourseDataService,
    private coursePage: CoursePageService,
    private quizService: QuizService,
    private writingService: WritingAssignmentService
  ) {}

  ngOnInit(): void {
    this.coursePage.$curActivity
      .pipe(untilDestroyed(this))
      .subscribe((activity) => this.setActivity(activity));

    this.courseData.$activityData
      .pipe(untilDestroyed(this))
      .subscribe((activityData) => this.setActivityData(activityData));

    this.coursePage.$curPart.subscribe((part) => {
      if (part) {
        this.subject = part?.title ?? '';
      }
    });

    this.coursePage.$curActivityId
      .pipe(untilDestroyed(this))
      .subscribe((id) => {
        this.onActivityIdChange(id);
      });

    this.coursePage.$curStaticPart
      .pipe(untilDestroyed(this))
      .subscribe((part) => {
        this.staticPart = part;
      });
  }

  setActivity(activity: CourseActivity) {
    this.activity = activity;
    if (activity) {
      this.courseData.getActivity(activity.id).subscribe();
    } else {
      this.setActivityData(null);
    }
  }

  setActivityData(activityData: GeneralCourseActivityData) {
    this.activityData = activityData;
    this.isQuiz = activityData?.type === CourseActivityType.quiz;
    this.isWriting = activityData?.type === CourseActivityType.writing;

    this.isInformative =
      activityData?.type === CourseActivityType.reading ||
      activityData?.type === CourseActivityType.video;
    // || activityData?.type === CourseActivityType.assignment;

    this.isSeminar = activityData?.type === CourseActivityType.seminar;
    this.isAssignment = activityData?.type === CourseActivityType.assignment;

    if (this.isQuiz) {
      let data = this.activityData as CourseQuizActivityData;
      this.quizService.setQuiz(data);
    }

    if (this.isWriting) {
      let data = this.activityData as CourseWritingActivityData;
      this.writingService.setWritingPart(data?.content);
    }
  }

  private onActivityIdChange(id: number) {
    let isStatic = id < 0;

    window.scroll(0, 0);

    this.isNews = isStatic
      ? id === CoursePageService.STATIC_COURSE_PARTS[0].id
      : false;
    this.isResults = isStatic
      ? id === CoursePageService.STATIC_COURSE_PARTS[1].id
      : false;
    this.isCalendar = isStatic
      ? id === CoursePageService.STATIC_COURSE_PARTS[2].id
      : false;
    this.isGroups = isStatic
      ? id === CoursePageService.STATIC_COURSE_PARTS[3].id
      : false;
  }
}
