import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CoursePdfMaterial } from 'src/models/course-material/course-pdf-material';

@Component({
  selector: 'app-pdf-content',
  templateUrl: './pdf-content.component.html',
  styleUrls: ['./pdf-content.component.scss'],
})
export class PdfContentComponent implements OnInit {
  @Input() set pdfMaterial(material: CoursePdfMaterial) {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(material?.pdf);
  }

  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
