import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-course-progress',
  templateUrl: './course-progress.component.html',
  styleUrls: ['./course-progress.component.scss'],
})
export class CourseProgressComponent implements OnInit {
  @Input() progress;
  @Input() coursePointsProgress;

  constructor() {}

  ngOnInit(): void {}
}
