import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CoursePageService } from 'src/app/services/course-page.service';
import { NotificationsDataService } from 'src/app/services/notifications-data.service';
import { CourseAssignmentActivityData } from 'src/models/course-activity-data/course-assignment-activity-data';
import { CourseActivityGrade } from 'src/models/course-grades.ts/course-activity-grade';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CourseDataService } from 'src/app/services/course-data.service';
import {
  FileSubmission,
  FileUploadService,
} from 'src/app/services/file-upload.service';
import { CourseMaterialType } from 'src/enums/course-material.enum';
import { ToastService } from 'src/app/services/toast.service';

@UntilDestroy()
@Component({
  selector: 'app-assignment',
  templateUrl: './assignment.component.html',
  styleUrls: ['./assignment.component.scss'],
})
export class AssignmentComponent implements OnInit {
  private static readonly SAVE_SUCCESS_MSG = 'Uppgiften har sparats.';
  private static readonly SAVE_ERROR_MSG =
    'Ett fel inträffade vid sparning. Det måste vara en PDF-fil och får ej vara en PNG eller JPEG. Testa igen om det fungerar';

  infoText: string;
  allowedFileTypes: string[];
  gradingMotivationRequired: boolean;
  gradingMotivation = new FormControl('');
  assignmentResult: CourseActivityGrade;
  activityFileSubmissions: FileSubmission[];
  submitted: Boolean;

  private _assignment: CourseAssignmentActivityData;
  @Input() set assignment(assignment: CourseAssignmentActivityData) {
    this._assignment = assignment;

    for (const material of assignment?.content) {
      if (material.type == CourseMaterialType.submissionInfo) {
        this.infoText = material.submissionInfo.infoMessage;
        this.allowedFileTypes = material.submissionInfo.fileTypes.split(',');
        this.gradingMotivationRequired =
          material.submissionInfo.gradeMotivationRequired;
      }
    }
  }

  get assignment(): CourseAssignmentActivityData {
    return this._assignment;
  }

  private static readonly RESULTS_TAB_INDEX = 3;

  result: CourseActivityGrade;

  file: File;
  activityId: number;
  activeTabIndex = 1;
  closeResult = '';
  activityPointsData = {
    criterias: [],
    pointSummary: {},
  };

  constructor(
    private coursePageService: CoursePageService,
    private courseDataService: CourseDataService,
    private notificationsService: NotificationsDataService,
    private modalService: NgbModal,
    private fileUploadService: FileUploadService,
    private toastService: ToastService
  ) {}

  ngOnInit(): void {
    this.coursePageService.$showResult
      .pipe(untilDestroyed(this))
      .subscribe(
        () => (this.activeTabIndex = AssignmentComponent.RESULTS_TAB_INDEX)
      );

    this.coursePageService.$curActivityId.subscribe((activityId) => {
      this.activityId = activityId;
      if (activityId) {
        this.courseDataService.getActivityPoint(activityId).subscribe((res) => {
          this.activityPointsData = res;
        });
      }
      this.getActivityResult(activityId);
      this.getActivityFileSubmissions(activityId);
    });
  }

  private getActivityResult(activityId: number) {
    this.courseDataService.getActivityResult(activityId).subscribe((res) => {
      this.assignmentResult = res;
    });
  }

  getActivityFileSubmissions(activityId: number) {
    this.fileUploadService.getSubmissionFiles(activityId).subscribe((res) => {
      this.activityFileSubmissions = res;
      this.submitted = res.length > 0;
    });
  }

  onTabChange(event) {
    if (!this.assignment) return;

    /* if (event.nextId == AssignmentComponent.RESULTS_TAB_INDEX) {
      this.notificationsService
        .markGradesRead([this.assignment?.content.assignmentGrade.gradingId])
        .subscribe();
    } */
  }

  open(content) {
    console.log(
      'Motivation',
      this.gradingMotivation.value,
      '\nRequired:',
      this.gradingMotivationRequired
    );
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          if (result) {
            this.fileUploadService.upload(this.file, this.activityId).subscribe(
              (res) => {
                this.toastService.showSuccess(
                  AssignmentComponent.SAVE_SUCCESS_MSG
                );
                this.submitted = true;
              },
              (err) => {
                this.toastService.showDanger(
                  AssignmentComponent.SAVE_ERROR_MSG
                );
                console.log('Error: ', err);
              }
            );
          }
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (!input.files?.length) {
      return;
    }

    this.file = input.files[0];
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
