import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CourseDataService } from 'src/app/services/course-data.service';
import { Course } from 'src/models/course';
import { CourseNews } from 'src/models/course-news';

@UntilDestroy()
@Component({
  selector: 'app-course-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit {
  @Input() set active(active: boolean) {
    this._active = active;
    this.updateData();
  }

  _active: boolean;
  news: CourseNews[];

  course: Course;

  constructor(private courseDataService: CourseDataService) {}

  ngOnInit(): void {
    this.courseDataService.$course
      .pipe(untilDestroyed(this))
      .subscribe((course) => {
        this.onCourseChange(course);
      });
  }

  onCourseChange(course: Course | null) {
    this.course = course;
    this.updateData();
  }

  updateData() {
    if (!this._active) {
      return;
    }

    this.news = null;

    if (this.course) {
      this.courseDataService
        .getCourseNews(this.course.id)
        .subscribe((news) => (this.news = news));
    }
  }
}
