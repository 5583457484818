import { Component, Input, OnInit } from '@angular/core';
import { InfoCardItem } from 'src/models/course-material/info-card-item';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent implements OnInit {
  @Input() cardItem: InfoCardItem;

  constructor() {}

  ngOnInit(): void {}
}
