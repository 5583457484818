<div
  class="card"
  [routerLink]="'/course/' + courseData.id"
  [queryParams]="{ activityId: courseStartActivity }"
>
  <div class="image-container">
    <img
      class="course-image"
      [src]="courseData.imageUrl"
      *ngIf="courseData.imageUrl"
    />

    <div *ngIf="!courseData.imageUrl" class="course-image-placeholder">
      <h5>{{ courseData.courseCode }} - {{ courseData.studyPeriod.title }}</h5>
    </div>
  </div>

  <div class="info">
    <h5>{{ courseData.title }}</h5>

    <div class="course-admin">
      <img class="avatar" [src]="courseData.examiner.imageUrl" />
      <span
        >{{ courseData.examiner.name }} ({{ courseData.examiner.liuId }})</span
      >
    </div>

    <div
      class="account-badge-div"
      *ngFor="let part of courseGrades?.partsResults; let index = index"
    >
      <app-badge-label
        *ngIf="index == 0"
        [progress]="courseData.userParticipation?.progress || 0"
        [grade]="part.result"
        [marked]="true"
      >
      </app-badge-label>
    </div>

    <app-progress-bar
      *ngIf="isTakenCourse"
      [progress]="courseData.userParticipation.progress"
      [includeText]="true"
    ></app-progress-bar>

    <div class="course-details">
      <span>Nivå:</span> {{ getCourseLevelLabel(courseData.level) }}<br />

      <ng-container *ngIf="isTakenCourse">
        <span>Status:</span> {{ courseData.userParticipation.status }} <br />
        <span>Anmäld:</span>
        {{ courseData.userParticipation.registered | date: 'longDate' }}<br />
      </ng-container>
    </div>
  </div>
</div>
