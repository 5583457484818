<ngb-alert *ngIf="latestSubmission" [dismissible]="false" type="success">
  Du har redan lämnat in denna uppgift. Klicka på 'Lämna in igen' längst ner på
  sidan för att lämna in en ny version.
</ngb-alert>
<div class="content" [hidden]="!writingPart?.questions">
  <form [formGroup]="assignmentForm">
    <div style="color: orangered; text-align: right">
      <h4 style="display: inline">*</h4>
      <p style="display: inline">Obligatorisk</p>
    </div>
    <div
      class="form-group"
      formGroupName="questionInputs"
      [class.has-error]="assignmentForm.invalid"
    >
      <ng-container
        *ngFor="let questionData of writingPart?.questions; let index = index"
      >
        <div class="assignmentQuestion">
          <label for="text">
            <h4>
              {{ index + 1 }}. {{ questionData?.title_question }}
              <span
                style="color: orangered"
                *ngIf="questionData.required"
                [ngbTooltip]="'Obligatorisk'"
                ><b>*</b></span
              >
            </h4>
            <div class="custom-text" [innerHTML]="questionData?.question"></div>
          </label>
          <textarea
            (input)="wordCountUpdate()"
            [required]="questionData.required"
            [disabled]="!completedFetchingAnswers"
            [formControlName]="questionData?.id"
            placeholder="Skriv här..."
            rows="10"
            class="form-control"
          >
          </textarea>
        </div>
      </ng-container>
    </div>

    <div class="form-group" *ngIf="writingPart?.allowNotes == true">
      <label for="comment" style="white-space: pre-line">
        <h4>
          {{ writingPart?.notesTitle }}
          <span [ngbTooltip]="'Obligatorisk'" style="color: orangered"
            ><b>*</b></span
          >
        </h4>
        {{ writingPart?.notesDescription }}<br />
      </label>
      <textarea
        required
        class="borders"
        placeholder="Skriv här..."
        formControlName="comment"
        cols="30"
        rows="3"
        class="form-control"
      ></textarea>
    </div>

    <div class="alert alert-primary" style="margin-top: 20px">
      <i><b>Antal ord steg 1-3:</b> {{ wordCountMandatory }}</i>
    </div>

    <ng-template #content let-modal>
      <div class="modal-header">
        <h4>Lämna in</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Är du säker på att du vill lämna in?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="onModalSubmit(modal)">
          Lämna in
        </button>
      </div>
    </ng-template>
    <div class="buttons">
      <a
        class="btn btn-primary"
        [class.disabled]="!latestSubmission"
        [href]="
          apiBase +
          '/course/activity/submission/' +
          latestSubmission?.documentRetrievalToken +
          '/pdf'
        "
        target="_blank"
        ><i class="bi bi-filetype-pdf"></i> Ladda ner PDF</a
      >
      <button
        [disabled]="
          (grade && grade >= 2) ||
          !assignmentForm.valid ||
          wordCount < writingPart?.minLength ||
          wordCount > writingPart?.maxLength
        "
        class="btn btn-primary hand-in-btn"
        (click)="open(content)"
      >
        {{ latestSubmission ? 'Lämna in igen' : 'Lämna in' }}
      </button>
      <button
        class="btn btn-success"
        (click)="saveResults()"
        [disabled]="grade && grade >= 2"
      >
        Spara
      </button>
    </div>
  </form>

  <div class="wrap">
    <div
      class="getWords"
      [ngStyle]="{
        'background-color':
          wordCount < writingPart?.minLength ||
          wordCount > writingPart?.maxLength
            ? 'maroon'
            : 'green'
      }"
    >
      <div>Antal ord: {{ wordCount ? wordCount : 0 }}</div>
      <div>
        Krav: {{ writingPart?.minLength }} - {{ writingPart?.maxLength }}
      </div>
    </div>
  </div>
</div>
