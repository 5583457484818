import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-lazy-load-preview-image',
  templateUrl: './lazy-load-preview-image.component.html',
  styleUrls: ['./lazy-load-preview-image.component.scss'],
})
export class LazyLoadPreviewImageComponent implements OnInit {
  DISABLE_LAZY_LOADING = true; //for user tests

  @Input() imageId: number;

  imageHeight = '600px';
  imageWidth = '800px';

  loadPreview = false;
  loadMain = false;

  loadingMain = true;

  private previewObserver: IntersectionObserver;
  private mainObserver: IntersectionObserver;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {}

  onLoad() {
    this.loadingMain = false;
  }

  ngAfterViewInit() {
    this.previewObserver = this.generateIntersectionObserver(() => {
      this.loadPreview = true;
      this.previewObserver.disconnect();
    }, 0);

    this.mainObserver = this.generateIntersectionObserver(() => {
      this.loadMain = true;
      this.mainObserver.disconnect();
    }, 0.1);

    this.previewObserver.observe(this.el.nativeElement as HTMLElement);
    this.mainObserver.observe(this.el.nativeElement as HTMLElement);
  }

  ngOnDestroy() {
    if (this.previewObserver) this.previewObserver.disconnect();

    if (this.mainObserver) this.mainObserver.disconnect();
  }

  private generateIntersectionObserver(cb: () => void, threshold: number) {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting === true) {
          cb();
        }
      },
      {
        threshold,
      }
    );

    observer.observe(this.el.nativeElement as HTMLElement);

    return observer;
  }
}
