import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-group-accept-button',
  templateUrl: './group-accept-button.component.html',
  styleUrls: ['./group-accept-button.component.scss'],
})
export class GroupAcceptButtonComponent implements OnInit {
  @Input() groupId: number;
  @Input() accepted: boolean;

  @Output() acceptEvent = new EventEmitter<number>();
  @Output() denyEvent = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  public accept() {
    this.acceptEvent.emit(this.groupId);
  }

  public deny() {
    this.denyEvent.emit(this.groupId);
  }
}
