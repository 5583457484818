<div class="content-container">
  <img
    *ngFor="let course of courseOverviews"
    [courseData]="course"
    src="{{ course.examiner.imageUrl }}"
    alt=""
  />

  <div class="account-page-badges" *ngFor="let course of courseOverviews">
    <h4>Badges</h4>
    <app-badge-label [progress]="course.progress"> </app-badge-label>
  </div>

  <div class="account-page-courses">
    <app-course-card
      *ngFor="let course of courseOverviews"
      [courseData]="course"
    ></app-course-card>
  </div>
</div>
