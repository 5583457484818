<div
  class="root"
  [class.selectState]="!isPostState"
  [class.postState]="isPostState"
  [hidden]="!curPart"
>
  <div
    *ngFor="let option of curPart?.options; let index = index"
    class="option borders"
    [class.imageOption]="curPart.isImageAnswers"
    [class.unselected]="!selected[index]"
    [class.selected]="selected[index]"
    [class.correct]="selected[index] && option.isCorrect"
    [class.incorrect]="selected[index] && !option.isCorrect"
    (click)="toggleSelect(index)"
  >
    <ng-container *ngIf="curPart.isImageAnswers">
      <img class="borders" [src]="option.title | imageUrl" loading="lazy" />
    </ng-container>

    <ng-container *ngIf="!curPart.isImageAnswers">
      {{ option.title }}
    </ng-container>
  </div>
</div>
