<div class="pointsContainer">
  <div *ngIf="!coursePartPointsData?.coursePartPoints; else point_table">
    <h4>Det finns inga poäng på denna del.</h4>
  </div>
  <ng-template #point_table>
    <table class="overview-table">
      <thead>
        <td colspan="2"></td>

        <th colspan="3" class="center">Poäng</th>
      </thead>
      <thead>
        <th>Aktivitet</th>
        <th>Beskrivning</th>

        <th>
          <div class="cell center">Kurspoäng</div>
        </th>
        <th>
          <div class="cell center">Mästarpoäng</div>
        </th>
        <th>
          <div class="cell center">Ordning</div>
        </th>
      </thead>
      <tbody *ngFor="let activity of coursePartPointsData?.activities">
        <tr *ngFor="let criteria of activity.criterias; let i = index">
          <td
            *ngIf="i === 0"
            [attr.rowspan]="activity.criterias.length"
            class="bold"
          >
            {{ activity.courseActivityTitle }}
          </td>
          <td>{{ criteria.description }}</td>

          <td
            class="center"
            *ngIf="criteria.criteriaPoints.course.available !== 0"
          >
            {{
              criteria.pointTypeKey === 'course'
                ? criteria.criteriaPoints.course.earned +
                  ' / ' +
                  criteria.criteriaPoints.course.available
                : null
            }}
          </td>
          <td
            class="center"
            *ngIf="criteria.criteriaPoints.course.available === 0"
          ></td>
          <td
            class="center"
            *ngIf="criteria.criteriaPoints.master.available !== 0"
          >
            {{
              criteria.pointTypeKey === 'master'
                ? criteria.criteriaPoints.master.earned +
                  ' / ' +
                  criteria.criteriaPoints.master.available
                : null
            }}
          </td>
          <td
            class="center"
            *ngIf="criteria.criteriaPoints.master.available === 0"
          ></td>
          <td
            class="center"
            *ngIf="criteria.criteriaPoints.order.available !== 0"
          >
            {{
              criteria.pointTypeKey === 'order'
                ? criteria.criteriaPoints.order.earned +
                  ' / ' +
                  criteria.criteriaPoints.order.available
                : null
            }}
          </td>
          <td
            class="center"
            *ngIf="criteria.criteriaPoints.order.available === 0"
          ></td>
        </tr>
      </tbody>
      <tfoot>
        <tr class="bold">
          <td>Summa</td>

          <td></td>

          <td
            class="center"
            *ngIf="
              coursePartPointsData?.coursePartPoints.course.available !== 0
            "
          >
            {{
              coursePartPointsData?.coursePartPoints?.course?.earned +
                ' / ' +
                coursePartPointsData?.coursePartPoints?.course?.available
            }}
          </td>
          <td
            class="center"
            *ngIf="
              coursePartPointsData?.coursePartPoints.course.available === 0
            "
          ></td>
          <td
            class="center"
            *ngIf="
              coursePartPointsData?.coursePartPoints.master.available !== 0
            "
          >
            {{
              coursePartPointsData?.coursePartPoints?.master?.earned +
                ' / ' +
                coursePartPointsData?.coursePartPoints?.master?.available
            }}
          </td>
          <td
            class="center"
            *ngIf="
              coursePartPointsData?.coursePartPoints.master.available === 0
            "
          ></td>
          <td
            class="center"
            *ngIf="coursePartPointsData?.coursePartPoints.order.available !== 0"
          >
            {{
              coursePartPointsData?.coursePartPoints?.order?.earned +
                ' / ' +
                coursePartPointsData?.coursePartPoints?.order?.available
            }}
          </td>
          <td
            class="center"
            *ngIf="coursePartPointsData?.coursePartPoints.order.available === 0"
          ></td>
        </tr>
      </tfoot>
    </table>
  </ng-template>
</div>
