<nav
  ngbNav
  #nav="ngbNav"
  class="nav-tabs responsive-nav equal-widths"
  [(activeId)]="activeTabIndex"
  (navChange)="onTabChange($event)"
>
  <ng-container [ngbNavItem]="1">
    <a ngbNavLink>Seminarium</a>
    <ng-template ngbNavContent>
      <app-informative-activity
        [materials]="seminar?.content.info"
      ></app-informative-activity>
      <app-activity-point-overview
        *ngIf="activityPointsData?.criterias?.length != 0"
        [activityCriterias]="activityPointsData.criterias"
        [pointSummary]="activityPointsData.pointSummary"
        [withResult]="false"
      >
      </app-activity-point-overview>
    </ng-template>
  </ng-container>

  <ng-container [ngbNavItem]="2">
    <a ngbNavLink>Bedömning</a>
    <ng-template ngbNavContent>
      <table class="table">
        <tr>
          <th>Betyg</th>
          <th>Kommentar</th>
          <th>Datum</th>
        </tr>
        <tr>
          <td>{{ result?.grade | gradeLabel }}</td>
          <td>{{ result?.feedback }}</td>
          <td>{{ result?.date | date: 'yy-MM-dd HH:mm' }}</td>
        </tr>
      </table>

      <app-activity-point-overview
        *ngIf="activityPointsData?.criterias?.length != 0"
        [activityCriterias]="activityPointsData.criterias"
        [pointSummary]="activityPointsData.pointSummary"
        [withResult]="true"
      >
      </app-activity-point-overview>
      <!-- <app-grade-criterias-description-wrapper
        [grade]="seminar?.content.seminarGrade"
      ></app-grade-criterias-description-wrapper> -->
    </ng-template>
  </ng-container>

  <ng-container *ngIf="seminar?.content.requiredComplementary" [ngbNavItem]="3">
    <a ngbNavLink>Komplettering</a>
    <ng-template ngbNavContent>
      <app-complementary [grade]="grade"></app-complementary>
    </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
