import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Course } from 'src/models/course';
import { GeneralCourseActivityData } from 'src/models/course-activity-data/general-course-activity-data';
import { CourseAssignmentSubmitData } from 'src/models/course-assignment-submit-data';
import { CourseGrades } from 'src/models/course-grades.ts';
import { CourseActivityGrade } from 'src/models/course-grades.ts/course-activity-grade';
import { CourseCollection } from 'src/models/course-collection';
import { CourseNews } from 'src/models/course-news';
import { CourseOverview } from 'src/models/course-overview';
import { StoredAssignmentAnswers } from 'src/models/stored-assignment-answers';
import { NotificationsDataService } from './notifications-data.service';
import { GroupMember } from 'src/models/group-member';
import { ActivitySubmission } from 'src/models/course-activity';

@Injectable({
  providedIn: 'root',
})
export class CourseDataService {
  private courses: CourseOverview[];
  private coursesSubject = new BehaviorSubject<CourseOverview[]>(null);
  $courses = this.coursesSubject.asObservable();

  private activeCourse: Course;
  private activeCourseSubject = new BehaviorSubject<Course>(null);
  $course = this.activeCourseSubject.asObservable();

  private activityDataSubject = new BehaviorSubject<GeneralCourseActivityData>(
    null
  );
  $activityData = this.activityDataSubject.asObservable();

  private cachedActivities = new Map<number, GeneralCourseActivityData>();
  private courseNews: CourseNews[] = null;

  private results: CourseGrades;
  private resultsSubject = new BehaviorSubject<CourseGrades>(null);
  $results = this.resultsSubject.asObservable();

  private courseDataPoints = new BehaviorSubject<any>(null);
  $courseDataPoints = this.courseDataPoints.asObservable();

  private activitiesResultsMap = new Map<number, CourseActivityGrade>();
  private cachedSubmissionAnswers = new Map<number, StoredAssignmentAnswers>();

  constructor(private http: HttpClient) {
    this.$courses.subscribe((courses) => (this.courses = courses));
    this.$results.subscribe((results) => (this.results = results));
  }

  public getActivityPoint(activityId: number) {
    return this.http.get<any>(
      `${environment.api}/course/activity/${activityId}/points`
    );
  }

  public acceptGroup(courseId: number, groupId: number) {
    return this.http.put<Boolean>(`${environment.api}/groups/accept`, {
      courseId: courseId,
      groupId: groupId,
    });
  }

  public declineGroup(courseId: number, groupId: number) {
    return this.http.put<Boolean>(`${environment.api}/groups/decline`, {
      courseId: courseId,
      groupId: groupId,
    });
  }

  public getCourseCollections(courseId: number) {
    return this.http.get<CourseCollection[]>(
      `${environment.api}/course/${courseId}/groups`
    );
  }

  public getCoursePointsData(courseId: number) {
    return this.http.get<any>(`${environment.api}/course/${courseId}/points`);
  }

  public checkRegister(courseId: number) {
    return this.http.get<number[]>(
      `${environment.api}/groups/check_register/${courseId}`
    );
  }

  public registerForGroup(
    courseId: number,
    collectionId: number,
    groupId: number
  ) {
    return this.http.put<GroupMember>(`${environment.api}/groups/register`, {
      courseId: courseId,
      collectionId: collectionId,
      groupId: groupId,
    });
  }

  public unregisterForGroup(courseId: number, groupId: number) {
    return this.http.put<any>(`${environment.api}/groups/unregister`, {
      courseId: courseId,
      groupId: groupId,
    });
  }

  public getCourse(courseId: number) {
    let res: Observable<Course>;
    if (this.activeCourse?.id === courseId) res = of(this.activeCourse);

    this.clearCachedDataForCurrentCourse();

    res = this.http.get<Course>(`${environment.api}/course/${courseId}`);

    return res.pipe(
      tap((res) => {
        this.setActiveCourse(res);

        if (!this.results) {
          this.emitResultsMetadata();
        }
      })
    );
  }

  public getCourseNews(courseId: number) {
    if (this.courseNews) {
      return of(this.courseNews);
    }

    return this.http
      .get<CourseNews[]>(`${environment.api}/course/${courseId}/news`)
      .pipe(
        tap((res) => {
          this.courseNews = res;
        })
      );
  }

  public getCourseOverviews() {
    if (this.courses) return of(this.courses);

    return this.http
      .get<CourseOverview[]>(`${environment.api}/course/all`)
      .pipe(tap((res) => this.coursesSubject.next(res)));
  }

  public getActivity(
    activityId: number
  ): Observable<GeneralCourseActivityData> {
    let res: Observable<GeneralCourseActivityData>;

    this.activityDataSubject.next(null);

    let cachedActivity = this.cachedActivities.get(activityId);
    let errorPage;

    if (cachedActivity) {
      res = of(cachedActivity);
    } else {
      res = this.http
        .get<GeneralCourseActivityData>(
          `${environment.api}/course/activity/student_view/${activityId}`
        )
        .pipe(
          tap(
            (res) => {
              this.cachedActivities.set(activityId, res);
            },
            (err) => {
              if (err.status === 403) {
                errorPage = {
                  content: [
                    {
                      title: '',
                      text: "<h1>403 Forbidden</h1><p>You don't have the permission to access the requested resource. It is either read-protected or not readable by the server.</p>",
                      type: 2,
                      expandable: null,
                    },
                  ],
                  id: 4,
                  isAdminGraded: false,
                  isOnlyPassOrFail: false,
                  title: 'Error',
                  type: 2,
                };
              }
            }
          )
        );
    }

    res = res.pipe(
      tap(
        (res) => {
          this.activityDataSubject.next(res);
        },
        (err) => {
          this.activityDataSubject.next(errorPage);
        }
      )
    );

    return res;
  }

  public markActivityAsDone(activityId: number, isDone: boolean) {
    return this.http
      .post<any>(`${environment.api}/course/activity/${activityId}/set_done`, {
        done: isDone,
      })
      .pipe(
        tap((res) => {
          const activity = _.chain(this.activeCourse.parts)
            .map((part) => part.activities)
            .flatten()
            .find((activity) => activity.id === activityId)
            .value();

          const next_activity = _.chain(this.activeCourse.parts)
            .map((part) => part.activities)
            .flatten()
            .find((activity) => activity.id === activityId + 1)
            .value();

          activity.finished = isDone;
          this.activeCourse.progress = res.progress;
          if (res.new_unlocked !== -1) {
            next_activity.unlocked = true;
          }

          this.activeCourseSubject.next(this.activeCourse);
        })
      );
  }

  public getCourseResults(courseId) {
    if (this.results && !this.results.isMetaData) {
      this.resultsSubject.next(this.results);
      return of(this.results);
    }

    return this.http
      .get<CourseGrades>(`${environment.api}/course/${courseId}/results`)
      .pipe(
        tap((res) => {
          this.setCourseResults(res);
        })
      );
  }

  public getActivityResult(activityId) {
    const localCopy = this.activitiesResultsMap.get(activityId);

    if (localCopy) {
      return of(localCopy);
    }

    return this.http
      .get<CourseActivityGrade>(
        `${environment.api}/course/activity/${activityId}/result`
      )
      .pipe(
        tap((res) => {
          this.activitiesResultsMap.set(activityId, res);
        })
      );
  }

  public getActivitySubmissions(activityId: number) {
    return this.http.get<ActivitySubmission[]>(
      `${environment.api}/course/activity/submission/${activityId}`
    );
  }

  public getActivitySubmissionAnswers(activityId: number) {
    let cachedAnswers = this.cachedSubmissionAnswers.get(activityId);

    if (cachedAnswers) {
      return of(cachedAnswers);
    }

    return this.http
      .get<StoredAssignmentAnswers>(
        `${environment.api}/course/activity/${activityId}/answers`
      )
      .pipe(
        tap((res) => {
          if (res) {
            this.cachedSubmissionAnswers.set(activityId, res);
          }
        })
      );
  }

  public storeAcitvitySubmissionAnswers(
    activityId: number,
    submitData: CourseAssignmentSubmitData
  ) {
    return this.http
      .post<number>(
        `${environment.api}/course/activity/${activityId}/answers`,
        submitData
      )
      .pipe(
        tap((res) => {
          if (res) {
            this.cachedSubmissionAnswers.set(activityId, submitData);
          }
        })
      );
  }

  private setCourseResults(results: CourseGrades) {
    this.results = results;
    this.resultsSubject.next(results);

    _.chain(results?.partsResults)
      .map((part) => part.activitiesResults)
      .flatten()
      .forEach((activityRes) =>
        this.activitiesResultsMap.set(activityRes.activityId, activityRes)
      )
      .value();
  }

  private emitResultsMetadata() {
    const metadata: CourseGrades = {
      gradeDescription: '',
      isMetaData: true,
      partsResults: _.chain(this.activeCourse?.parts)
        .filter((part) => part.isGraded)
        .map((part) => {
          return {
            id: part.id,
            title: part.title,
            activitiesResults: _.chain(part?.activities)
              .filter((activity) => activity?.isAdminGraded)
              .map((activity) => {
                return {
                  activityId: activity.id,
                  activityTitle: activity.title,
                };
              })
              .value(),
            resultDescription: '',
          };
        })
        .value(),
    };

    this.resultsSubject.next(metadata);
  }

  private clearCachedDataForCurrentCourse() {
    this.courseNews = null;

    this.setActiveCourse(null);
    this.clearCachedActivities();
    this.clearCourseResults();
    this.clearCachedSubmissionAnswers();
  }

  private clearCachedSubmissionAnswers() {
    this.cachedSubmissionAnswers.clear();
  }

  private clearCachedActivities() {
    this.activityDataSubject.next(null);
    this.cachedActivities.clear();
  }

  private clearCourseResults() {
    this.resultsSubject.next(null);
    this.activitiesResultsMap.clear();
  }

  private setActiveCourse(course: Course) {
    this.activeCourse = course;
    this.activeCourseSubject.next(course);
  }
}
