import { Component, Input, OnInit } from '@angular/core';
import { WritingPart } from 'src/models/course-writing/writing-part';
import { WritingAssignmentService } from 'src/app/services/writing-assignment.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CourseDataService } from 'src/app/services/course-data.service';
import { CourseActivityGrade } from 'src/models/course-grades.ts/course-activity-grade';
import { CoursePageService } from 'src/app/services/course-page.service';
import { StoredAssignmentAnswers } from 'src/models/stored-assignment-answers';
import { NotificationsDataService } from 'src/app/services/notifications-data.service';
import { ActivitySubmission } from 'src/models/course-activity';

@UntilDestroy()
@Component({
  selector: 'app-writing',
  templateUrl: './writing.component.html',
  styleUrls: ['./writing.component.scss'],
})
export class WritingComponent implements OnInit {
  @Input() set active(active: boolean) {
    this._active = active;
  }

  private static readonly TAB_INDEXES = {
    DESCRIPTION: 1,
    SUBMISSION: 2,
    RESULT: 3,
    HISTORY: 4,
  };

  tabTitles = ['Beskrivning', 'Inlämning', 'Bedömning', 'Historik'];

  _active = false;
  activeTab = 2;
  activityId: number;
  latestSubmission?: ActivitySubmission;
  activityPointsData = {
    criterias: [],
    pointSummary: {},
  };

  writingPart: WritingPart;
  assignmentResult: CourseActivityGrade;

  fetchedAnswers = false;
  savedAnswers: StoredAssignmentAnswers;
  activitySubmissions: ActivitySubmission[];

  constructor(
    private writingService: WritingAssignmentService,
    private courseDataService: CourseDataService,
    private coursePageService: CoursePageService,
    private notificationsService: NotificationsDataService
  ) {}

  ngOnInit(): void {
    this.writingService.$writingPart
      .pipe(untilDestroyed(this))
      .subscribe((res) => {
        this.writingPart = res;
      });

    this.coursePageService.$curActivityId
      .pipe(untilDestroyed(this))
      .subscribe((activityId) => {
        this.setActivityId(activityId);
        this.getActivityPointData(activityId);
        this.getActivityResult(activityId);
        this.getActivitySubmissions(activityId);
      });
  }

  onTabChange(event) {
    this.activeTab = event.nextId;
    this.OnViewChange();
  }

  getActivitySubmissions(activityId: number) {
    this.courseDataService
      .getActivitySubmissions(activityId)
      .subscribe((res) => {
        this.activitySubmissions = res;
        const submissions = res
          .filter((activitySubmission) => activitySubmission.submitted)
          .sort((a, b) => b.version - a.version);

        if (submissions.length) {
          this.latestSubmission = submissions[0];
        }
      });
  }

  getActivityPointData(activityId: number) {
    this.courseDataService.getActivityPoint(activityId).subscribe((res) => {
      this.activityPointsData = res;
    });
  }

  private getActivityResult(activityId: number) {
    this.courseDataService.getActivityResult(activityId).subscribe((res) => {
      this.assignmentResult = res;
    });
  }

  private setActivityId(activityId: number) {
    this.activityId = activityId;
    this.activeTab = 1;

    this.fetchedAnswers = false;
    this.savedAnswers = null;

    setTimeout(() => this.OnViewChange(), 1);
  }

  private OnViewChange() {
    if (
      !this._active ||
      this.activityId === undefined ||
      this.activityId == null
    ) {
      return;
    }

    if (!this.fetchedAnswers) {
      this.getStoredAnswers();
      this.fetchedAnswers = true;
    }

    switch (this.activeTab) {
      case WritingComponent.TAB_INDEXES.DESCRIPTION:
        break;
      case WritingComponent.TAB_INDEXES.SUBMISSION:
        break;
      case WritingComponent.TAB_INDEXES.RESULT:
        this.onResultOpen();
      case WritingComponent.TAB_INDEXES.HISTORY:
        break;
    }
  }

  private getStoredAnswers() {
    this.courseDataService
      .getActivitySubmissionAnswers(this.activityId)
      .subscribe((res) => {
        this.savedAnswers = res;
      });
  }

  private onResultOpen() {
    this.courseDataService
      .getActivityResult(this.activityId)
      .subscribe((res) => {
        this.assignmentResult = res;
        this.notificationsService.markGradesRead([res.gradingId]).subscribe();
      });
  }
}
