import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { CourseDataService } from 'src/app/services/course-data.service';
import { CoursePageService } from 'src/app/services/course-page.service';
import { FeedbackService } from 'src/app/services/feedback.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { CourseActivityType } from 'src/enums/course-activity.enum';
import { Course } from 'src/models/course';
import { CourseActivity } from 'src/models/course-activity';

@UntilDestroy()
@Component({
  selector: 'app-course-page',
  templateUrl: './course-page.component.html',
  styleUrls: ['./course-page.component.scss'],
})
export class CoursePageComponent implements OnInit {
  activity: CourseActivity;
  activityId: number;
  collectionId: number;
  course: Course;

  isQuiz = false;

  constructor(
    private coursePageService: CoursePageService,
    private courseDataService: CourseDataService,
    private feedbackService: FeedbackService,
    private toolbarService: ToolbarService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let courseId = parseInt(params.get('courseId'));
      this.onParamsChange(courseId);
    });

    this.route.queryParams.subscribe((params) =>
      this.onQueryParamsChange(params)
    );

    this.coursePageService.$curGroupCollectionId
      .pipe(untilDestroyed(this))
      .subscribe((collectionId) => (this.collectionId = collectionId));

    this.courseDataService.$course
      .pipe(untilDestroyed(this))
      .subscribe((course) => (this.course = course));

    this.coursePageService.$curActivity
      .pipe(untilDestroyed(this))
      .subscribe((activity) => this.setActivity(activity));

    this.courseDataService.$course
      .pipe(untilDestroyed(this))
      .subscribe((course) => this.onCourseChange(course));

    this.coursePageService.$curActivityId
      .pipe(untilDestroyed(this))
      .subscribe((id) => this.setActivityId(id));
  }

  private setActivity(activity: CourseActivity) {
    this.activity = activity;
    this.isQuiz = activity?.type === CourseActivityType.quiz;
    this.feedbackService.clearMessage();
  }

  onCourseChange(course: Course) {
    if (course) {
      this.toolbarService.setTitle({
        defaultTitle: course.title,
        shortTitle: `${course.courseCode} (${course.studyPeriod.title})`,
      });
    } else {
      this.toolbarService.setTitle({
        defaultTitle: '...',
      });
    }
  }

  private setActivityId(id: number) {
    this.activityId = id;
    this.updateActivityQueryParameters();
  }

  private updateActivityQueryParameters() {
    if (!this.activityId) {
      return;
    }

    let params =
      this.activityId === CoursePageService.STATIC_COURSE_PARTS[3].id
        ? { activityId: this.activityId, collection: this.collectionId }
        : { activityId: this.activityId };

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: params,
      // queryParamsHandling: 'merge'
    });
  }

  private onQueryParamsChange(params: Params) {
    const activityId = +params['activityId'];

    if (activityId === undefined || activityId === null) {
      this.setActivityId(CoursePageService.STATIC_COURSE_PARTS[0].id);
    }

    if (activityId === this.activityId) {
      return;
    }

    this.coursePageService.setActivityId(activityId, true);
  }

  private onParamsChange(courseId: number) {
    this.coursePageService.openCourse(courseId).subscribe(() => {});
  }
}
