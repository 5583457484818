import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { ToolbarLink } from 'src/models/toolbar-link';
import { ToolbarTitle } from 'src/models/toolbar-title';

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {
  private defaultTitle: ToolbarTitle = {
    defaultTitle: 'Teaches',
    shortTitle: 'Teaches',
  };

  private toolbarTitleSubject = new BehaviorSubject<ToolbarTitle>(
    this.defaultTitle
  );
  public toolbarTitleObservable = this.toolbarTitleSubject.asObservable();

  private customToolbarItems: ToolbarLink[] = [];
  private customToolbarItemsSubject = new BehaviorSubject<ToolbarLink[]>(
    this.customToolbarItems
  );
  public customToolbarItemsObservable =
    this.customToolbarItemsSubject.asObservable();

  constructor(private titleService: Title) {}

  public setTitle(title: ToolbarTitle) {
    if (title) {
      this.toolbarTitleSubject.next(title);
      this.titleService.setTitle(title.defaultTitle);
    } else {
      this.setTitle(this.defaultTitle);
    }
  }

  public setCustomToolbarItems(items: ToolbarLink[]) {
    this.customToolbarItems = items;
    this.customToolbarItemsSubject.next(items);
  }

  public clearCustomToolbarItems() {
    this.setCustomToolbarItems([]);
  }
}
