import { Component, Input, OnInit } from '@angular/core';
import { FileSubmission } from 'src/app/services/file-upload.service';
import { CourseActivityGrade } from 'src/models/course-grades.ts/course-activity-grade';
import { SubmissionEvent } from 'src/models/course-writing/submission-event';

@Component({
  selector: 'app-assignment-feedback',
  templateUrl: './assignment-feedback.component.html',
  styleUrls: ['./assignment-feedback.component.scss'],
})
export class AssignmentFeedbackComponent implements OnInit {
  @Input() activityFileSubmissions: FileSubmission[];
  @Input() assignmentResult: CourseActivityGrade;

  headers = ['Händelse', 'Kommentar', 'Fil / Betyg'];
  rows: SubmissionEvent[] = [];

  /* rows: SubmissionEvent[] = [
    {
      date: new Date(),
      submittedBy: 'emmal084',
      assignmentSubmission: {
        filename: '<a href=""><b>PDF</b></a>',
        version: 1,
      },
    },
    {
      date: new Date(),
      submittedBy: 'asebe123',
      comment: `
      Bra jobbat med planeringen Kalle!
      `,
      feedback: {
        grade: 2,
        forVersion: 1,
      },
    },
  ]; */

  constructor() {}

  ngOnInit(): void {}
}
