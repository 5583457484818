import { Component, Input, OnInit } from '@angular/core';
import { Grade } from 'src/enums/grade';

@Component({
  selector: 'app-result-label',
  templateUrl: './result-label.component.html',
  styleUrls: ['./result-label.component.scss'],
})
export class ResultLabelComponent implements OnInit {
  readonly statusIcons = {
    failed: 'circle-fill',
    complementary: 'circle-fill',
    passed: 'check-circle-fill',
    unassigned: 'circle',
  };

  readonly statusDescriptions = {
    failed: 'Underkänt',
    complementary: 'Komplettering',
    passed: 'Godkänt',
  };

  @Input() grade: Grade;
  @Input() marked = false;

  @Input() isCriteria = false;
  @Input() grow = false;

  constructor() {}

  ngOnInit(): void {}
}
