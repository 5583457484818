import { Component, Input, OnInit } from '@angular/core';
import { Grade } from 'src/enums/grade';
import { GradeDescription } from 'src/models/grade-description';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-activity-grade-criteria',
  templateUrl: './activity-grade-criteria.component.html',
  styleUrls: ['./activity-grade-criteria.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class ActivityGradeCriteriaComponent implements OnInit {
  @Input() outlined = true;
  @Input() result: Grade;
  @Input() grades: GradeDescription[];
  @Input() grade: any;
  @Input() date: Date;

  @Input() comment: string = 'Bra jobbat!';

  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }
  open(content) {
    this.modalService.open(content);
  }

  ngOnInit(): void {}
}
