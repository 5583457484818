<ul>
  <li *ngFor="let msg of news" class="borders only-borders-bottom">
    <div class="overview">
      <h6>{{ msg.title }}</h6>
      <span>{{ msg.date | date }}</span>
    </div>

    <div class="content" [innerHTML]="msg.message"></div>
  </li>
  <li *ngIf="!news?.length" class="borders only-borders-bottom">
    <div class="overview">
      <em>
        Det finns inga kursnyheter.
      </em>
    </div>
  </li>
</ul>
