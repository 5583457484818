import { Component, Input, OnInit } from '@angular/core';
import { CourseMaterialType } from 'src/enums/course-material.enum';
import { GeneralCourseMaterial } from 'src/models/course-material/general-course-material';

@Component({
  selector: 'app-course-item',
  templateUrl: './course-item.component.html',
  styleUrls: ['./course-item.component.scss'],
})
export class CourseItemComponent implements OnInit {
  @Input() courseMaterialItem: GeneralCourseMaterial;

  readonly videoType = CourseMaterialType.video;
  readonly textType = CourseMaterialType.text;
  readonly imageType = CourseMaterialType.image;
  readonly pdfType = CourseMaterialType.pdf;
  readonly cardType = CourseMaterialType.card;

  expanded = false;

  constructor() {}

  ngOnInit(): void {}

  toggleExpanded() {
    this.expanded = !this.expanded;
  }
}
