<div class="pointsContainer">
  <h5 *ngIf="!withResult">Möjliga poäng att få</h5>
  <h5 *ngIf="withResult">Aktivitetens poängresultat</h5>
  <table class="overview-table">
    <thead>
      <td></td>
      <th colspan="3" class="center">Poäng</th>
    </thead>
    <thead>
      <th>Beskrivning</th>
      <th>
        <div class="cell center">Kurspoäng</div>
      </th>
      <th>
        <div class="cell center">Mästarpoäng</div>
      </th>
      <th>
        <div class="cell center">Ordning</div>
      </th>
    </thead>
    <tbody *ngIf="!withResult">
      <tr *ngFor="let activityCriteria of activityCriterias">
        <td>{{ activityCriteria.description }}</td>
        <td class="center">
          {{
            activityCriteria.pointTypeId == 1
              ? activityCriteria.availablePoints
              : null
          }}
        </td>
        <td class="center">
          {{
            activityCriteria.pointTypeId == 2
              ? activityCriteria.availablePoints
              : null
          }}
        </td>
        <td class="center">
          {{
            activityCriteria.pointTypeId == 3
              ? activityCriteria.availablePoints
              : null
          }}
        </td>
      </tr>
    </tbody>
    <tbody *ngIf="withResult">
      <tr *ngFor="let activityCriteria of activityCriterias">
        <td>{{ activityCriteria.description }}</td>
        <td class="center">
          {{
            activityCriteria.pointTypeId == 1
              ? activityCriteria.earnedPoints +
                ' / ' +
                activityCriteria.availablePoints
              : null
          }}
        </td>
        <td class="center">
          {{
            activityCriteria.pointTypeId == 2
              ? activityCriteria.earnedPoints +
                ' / ' +
                activityCriteria.availablePoints
              : null
          }}
        </td>
        <td class="center">
          {{
            activityCriteria.pointTypeId == 3
              ? activityCriteria.earnedPoints +
                ' / ' +
                activityCriteria.availablePoints
              : null
          }}
        </td>
      </tr>
    </tbody>

    <tfoot *ngIf="!withResult">
      <tr class="bold">
        <td>Summa</td>
        <td class="center">
          {{ pointSummary?.course?.available }}
        </td>
        <td class="center">
          {{ pointSummary?.master?.available }}
        </td>
        <td class="center">
          {{ pointSummary?.order?.available }}
        </td>
      </tr>
    </tfoot>
    <tfoot *ngIf="withResult">
      <tr class="bold">
        <td>Summa</td>
        <td class="center" *ngIf="pointSummary.course.available > 0">
          {{
            pointSummary?.course?.earned +
              ' / ' +
              pointSummary?.course?.available
          }}
        </td>
        <td class="center" *ngIf="pointSummary.course.available === 0"></td>
        <td class="center" *ngIf="pointSummary.master.available > 0">
          {{
            pointSummary?.master?.earned +
              ' / ' +
              pointSummary?.master?.available
          }}
        </td>
        <td class="center" *ngIf="pointSummary.master.available === 0"></td>
        <td class="center" *ngIf="pointSummary.order.available > 0">
          {{
            pointSummary?.order?.earned + ' / ' + pointSummary?.order?.available
          }}
        </td>
        <td class="center" *ngIf="pointSummary.order.available === 0"></td>
      </tr>
    </tfoot>
  </table>
</div>
