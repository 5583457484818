<div class="content">
  <div class="infobox">
    <div class="face front">
      <p>{{ cardItem?.textFront }}</p>
    </div>
    <div class="face back">
      <p>{{ cardItem?.textBack }}</p>
    </div>
  </div>
</div>
