<div class="content">
  <form [formGroup]="complementaryForm">
    <div class="form-group">
      <h2>{{ writingPart.title }}</h2>
      <div>
        {{ writingPart.description }}
      </div>
      <ng-container *ngFor="let question of writingPart.questions">
        <div class="assignmentQuestion">
          <label for="text">
            <h4>{{ question.title_question }}</h4>
            <div>
              {{ question.question }}
            </div>
          </label>
          <textarea
            (input)="wordCountUpdate()"
            placeholder="Skriv här..."
            [formControlName]="question.id"
            rows="10"
            class="form-control"
          >
          </textarea>
        </div>
      </ng-container>
      <input
        id="complementary-pdf"
        type="file"
        accept=".pdf"
        multiple
        (change)="onNewFile($event)"
      />
      <div class="commentField" *ngIf="this.writingPart.allowNotes">
        <label for="text">
          <h4>
            {{ writingPart?.notesTitle }}
          </h4>
          {{ writingPart?.notesDescription }}<br />
        </label>
        <textarea
          (input)="wordCountUpdate()"
          placeholder="Skriv här..."
          formControlName="comment"
          rows="10"
          class="form-control"
        >
        </textarea>
      </div>
    </div>

    <ng-template #submitModal let-modal>
      <div class="modal-header">
        <h4>Lämna in</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="modal.dismiss('Cross click')"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Är du säker på att du vill lämna in?</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-primary" (click)="submitAnswers(modal)">
          Lämna in
        </button>
      </div>
    </ng-template>

    <div
      *ngIf="storedFileSubmissions && storedFileSubmissions.length !== 0"
      class="storedSubmissions"
    >
      <p><b> Upladdade filer </b></p>
      <table>
        <tr>
          <th>Namn</th>
          <th>Datum</th>
          <th>Hantera</th>
        </tr>
        <tr *ngFor="let storedSubmission of storedFileSubmissions">
          <td>
            {{ storedSubmission.filename }}
          </td>
          <td>
            {{ storedSubmission.submissionDate | date: 'yyyy-MM-dd HH:mm' }}
          </td>
          <td>
            <i class="bi-trash" (click)="removeFile(storedSubmission.id)"></i>
            <i class="bi-download"
              (click)="
                downloadFile(
                  storedSubmission.activityId,
                  storedSubmission.filename
                )
              "
            ></i>
          </td>
        </tr>
      </table>
    </div>

    <div class="buttons">
      <button
        [disabled]="
          !complementaryForm.valid ||
          wordCount < writingPart?.minLength ||
          grade >= 2
        "
        class="btn btn-primary hand-in-btn"
        (click)="openModal(submitModal)"
      >
        Lämna in
      </button>
      <button
        [disabled]="grade >= 2"
        class="btn btn-success"
        (click)="saveAnswers()"
      >
        Spara
      </button>
    </div>
  </form>

  <div class="wrap">
    <div
      class="getWords"
      [ngStyle]="{
        'background-color':
          wordCount < writingPart?.minLength ? 'maroon' : 'green'
      }"
    >
      <div>Antal ord: {{ wordCount ? wordCount : 0 }}</div>
      <div>Krav: {{ writingPart?.minLength }}</div>
    </div>
  </div>
</div>
