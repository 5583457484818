import { Component, Input, OnInit } from '@angular/core';
import { CourseVideoMaterial } from 'src/models/course-material/course-video-material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-video-content',
  templateUrl: './video-content.component.html',
  styleUrls: ['./video-content.component.scss'],
})
export class VideoContentComponent implements OnInit {
  @Input() courseVideo: CourseVideoMaterial;
  urlSafe: SafeResourceUrl;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(
      this.courseVideo.video
    );
  }
}
