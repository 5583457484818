<ng-container *ngIf="accepted">Du har bekräftat din plats!</ng-container>
<ng-container *ngIf="!accepted"
  ><button
    style="background-color: #28a745; border-color: #28a745"
    class="btn btn-primary"
    (click)="accept()"
  >
    <i style="color: black" class="bi-check"></i>
  </button>
  <button
    style="background-color: #dc3545; border-color: #dc3545"
    class="btn btn-primary"
    (click)="deny()"
  >
    <i style="color: black" class="bi-x"></i>
  </button>
</ng-container>
