import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserMenuService {
  private userMenuVisible = false;
  private userMenuVisibleSubject = new BehaviorSubject<boolean>(
    this.userMenuVisible
  );
  $userMenuVisible = this.userMenuVisibleSubject.asObservable();

  private notificationsVisible = false;
  private notificationsVisibleSubject = new BehaviorSubject<boolean>(
    this.notificationsVisible
  );
  $notificationsVisible = this.notificationsVisibleSubject.asObservable();

  constructor() {
    this.$userMenuVisible.subscribe(
      (visible) => (this.userMenuVisible = visible)
    );
    this.$notificationsVisible.subscribe(
      (visible) => (this.notificationsVisible = visible)
    );
  }

  toggleUserMenuVisible() {
    this.setUserMenuVisible(!this.userMenuVisible);
  }

  setUserMenuVisible(visible: boolean) {
    this.updateVisibleSubject(
      this.userMenuVisible,
      visible,
      this.userMenuVisibleSubject
    );
  }

  toggleUserNotificationsVisible() {
    this.setNotificationsVisible(!this.notificationsVisible);
  }

  setNotificationsVisible(visible: boolean) {
    this.updateVisibleSubject(
      this.notificationsVisible,
      visible,
      this.notificationsVisibleSubject
    );
  }

  private updateVisibleSubject(
    currVisible: boolean,
    newVisible: boolean,
    subject: Subject<boolean>
  ) {
    if (newVisible === currVisible) return;

    subject.next(newVisible);
  }
}
