import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { CourseQuizActivityData } from 'src/models/course-activity-data/course-quiz-activity-data';
import { Quiz } from 'src/models/quiz/quiz';
import { AnswerCheckResult } from 'src/models/quiz/quiz-answer-check-result';
import { QuizPart } from 'src/models/quiz/quiz-part';
import { QuizSelectPart } from 'src/models/quiz/quiz-select-part';
import { CoursePageService } from './course-page.service';

@Injectable({
  providedIn: 'root',
})
export class QuizService {
  private isPostState = new BehaviorSubject<boolean>(false);
  public $isPostState = this.isPostState.asObservable();

  private answerResult = new BehaviorSubject<AnswerCheckResult | null>(null);
  public $answerResult = this.answerResult.asObservable();

  private hasAnswers = new BehaviorSubject<boolean>(false);
  public $hasAnswers = this.hasAnswers.asObservable();

  private quizActivityId: number;
  private quiz: Quiz = null;
  private curQuiz = new BehaviorSubject<Quiz>(null);
  public $curQuiz = this.curQuiz.asObservable();

  private curQuizPartIndex: number;

  private curQuizPartIndexSubject = new BehaviorSubject<number>(0);
  public $curQuizPartIndex = this.curQuizPartIndexSubject.asObservable();

  private curQuizPart = new BehaviorSubject<QuizPart | QuizSelectPart>(null);
  public $curQuizPart = this.curQuizPart.asObservable();

  private onRetry = new Subject();
  public $onRetry = this.onRetry.asObservable();

  private failedParts: number[];
  private results = new BehaviorSubject<string[]>(null);
  public $results = this.results.asObservable();

  constructor(private coursePageService: CoursePageService) {}

  setQuiz(data: CourseQuizActivityData) {
    if (data?.id == this.quizActivityId) {
      return;
    }

    this.quiz = data?.content;
    this.quizActivityId = data?.id;
    this.curQuiz.next(this.quiz);

    this.setQuizPartIndex(0);
  }

  setQuizPartIndex(index: number) {
    this.curQuizPartIndex = index;
    this.curQuizPartIndexSubject.next(this.curQuizPartIndex);

    if (index === 0) {
      this.clearResults();
    }

    if (this.quiz) {
      this.curQuizPart.next(_.nth(this.quiz.parts, index));

      if (index == this.quiz.parts.length) {
        this.onFinish();
      }
    } else {
      this.curQuizPart.next(null);
    }

    this.setIsPostState(false);
  }

  goToNextQuizPart() {
    this.setQuizPartIndex(this.curQuizPartIndex + 1);
  }

  retry() {
    if (!_.find(this.failedParts, this.curQuizPartIndex)) {
      this.failedParts.push(this.curQuizPartIndex);
    }
    this.setIsPostState(false);
    this.onRetry.next();
  }

  setIsPostState(isPostState: boolean) {
    this.isPostState.next(isPostState);
  }

  setHasAnswers(hasAnswers: boolean) {
    this.hasAnswers.next(hasAnswers);
    if (!hasAnswers) {
      this.setAnswerResult(null);
    }
  }

  setAnswerResult(answerResult: AnswerCheckResult | null) {
    this.answerResult.next(answerResult);
  }

  private onFinish() {
    this.updateResults();
    this.coursePageService.setCurActivityDone(true).subscribe();
  }

  private updateResults() {
    const res = _.map(this.failedParts, (partIndex) => {
      return this.quiz.parts[partIndex].improvementText;
    });

    this.results.next(res);
  }

  private clearResults() {
    this.failedParts = [];
    this.results.next(null);
  }
}
