import { Component, Input, OnInit } from '@angular/core';
import { CourseActivityPointDescription } from 'src/models/course-activity-points';

@Component({
  selector: 'app-activity-point-overview',
  templateUrl: './activity-point-overview.component.html',
  styleUrls: ['./activity-point-overview.component.scss'],
})
export class ActivityPointOverviewComponent implements OnInit {
  @Input() activityCriterias: CourseActivityPointDescription['criterias'];
  @Input() pointSummary: CourseActivityPointDescription['pointSummary'];
  @Input() withResult: boolean;

  constructor() {}

  ngOnInit(): void {}
}
