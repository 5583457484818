<div class="root">
  <div *ngIf="isLoggedIn">
    <div [class.hidden]="hideToolbarForSmall" class="toolbar-container">
      <app-toolbar></app-toolbar>
    </div>

    <app-menu-container
      class="user-menu"
      (hide)="hideUserMenu()"
      [visible]="userMenuVisible"
    >
      <button class="btn btn-primary">Logga ut</button>
    </app-menu-container>

    <app-menu-container
      class="notifications"
      (hide)="hideNotifications()"
      [visible]="notificationsVisible"
    >
      <app-notifications></app-notifications>
    </app-menu-container>

    <div class="content" [class.expanded]="hideToolbarForSmall">
      <router-outlet></router-outlet>
    </div>

    <app-toasts-container></app-toasts-container>
  </div>

  <app-login-page *ngIf="!isLoggedIn"></app-login-page>
</div>
