import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { CourseHelper } from 'src/helpers/course-helper';
import { Course } from 'src/models/course';
import { CourseActivity } from 'src/models/course-activity';
import { CourseActivityIndex } from 'src/models/course-activity-index';
import { CoursePart } from 'src/models/course-part';
import { NavItem } from 'src/models/nav-item';
import { StaticCoursePart } from 'src/models/static-course-part';
import { CourseDataService } from './course-data.service';
import { NotificationsDataService } from './notifications-data.service';

@Injectable({
  providedIn: 'root',
})
export class CoursePageService {
  public static readonly STATIC_COURSE_PARTS: StaticCoursePart[] = [
    { title: 'Nyheter', iconName: 'envelope' } as StaticCoursePart,
    { title: 'Resultat', iconName: 'card-checklist' } as StaticCoursePart,
    { title: 'Schema', iconName: 'calendar3' } as StaticCoursePart,
    { title: 'Grupper', iconName: 'people' } as StaticCoursePart,
  ];

  private course: Course;

  private curActivityId: number = null;
  private curActivityIdSubject = new BehaviorSubject<number>(null);
  public $curActivityId = this.curActivityIdSubject.asObservable();

  private curCoursePartId: number = null;
  private curCoursePartIdSubject = new BehaviorSubject<number>(null);
  public $curCoursePartId = this.curCoursePartIdSubject.asObservable();

  private curPartSubject = new BehaviorSubject<CoursePart>(null);
  public $curPart = this.curPartSubject.asObservable();

  private curActivitySubject = new BehaviorSubject<CourseActivity>(null);
  public $curActivity = this.curActivitySubject.asObservable();

  private curStaticPartSubject = new BehaviorSubject<StaticCoursePart>(null);
  public $curStaticPart = this.curStaticPartSubject.asObservable();

  private showResultSubject = new Subject();
  public $showResult = this.showResultSubject.asObservable();

  private curGroupCollectionSubject = new BehaviorSubject<number>(null);
  public $curGroupCollectionId = this.curGroupCollectionSubject.asObservable();

  constructor(
    private courseDataService: CourseDataService,
    private notificationsService: NotificationsDataService
  ) {
    _.forEach(CoursePageService.STATIC_COURSE_PARTS, (part, index) => {
      part.id = index - CoursePageService.STATIC_COURSE_PARTS.length;
    });

    this.courseDataService.$course.subscribe((course) =>
      this.setCourse(course)
    );
  }

  openCourse(courseId: number) {
    return this.courseDataService.getCourse(courseId);
  }

  setCurGroupCollectionId(collectionId: number) {
    this.curGroupCollectionSubject.next(collectionId);
  }

  navBack() {
    let { part, activity } = this.getCurActivityIndex();

    activity--;
    if (activity < 0) {
      part--;

      let partActivities = this.getActivities(part);
      activity = partActivities.length - 1;
    }

    this.setActivityByIndex({ part, activity });
  }

  navForward() {
    let { part, activity } = this.getCurActivityIndex();
    activity++;

    let curPartActivities = this.getActivities(part);
    if (activity >= curPartActivities.length) {
      part++;
      activity = 0;
    }

    this.setActivityByIndex({ part, activity });
  }

  setActivityId(id: number, forceUpdate = false) {
    if (!forceUpdate && this.curActivityId == id) {
      return;
    }

    this.curActivityId = id;
    this.curActivityIdSubject.next(id);

    if (this.curActivityId == this.getActivityIdForStaticPartIndex(0)) {
      this.notificationsService
        .clearCourseNewsNotifications(this.course?.id)
        .subscribe();
    }

    this.updateCurActivity();
  }

  private setCoursePart() {
    const partIndex = CourseHelper.getPartIndexForActivityId(
      this.curActivityId,
      this.course
    );

    if (partIndex > -1 && this.course?.parts.length >= partIndex) {
      const part = this.course.parts[partIndex];

      if (this.curPartSubject.getValue()?.id !== part.id) {
        // Only update part if it actually changed
        this.curPartSubject.next(part);
      }
    }
  }

  setCoursePartId(id: number, forceUpdate = false) {
    if (!forceUpdate && this.curCoursePartId == id) {
      return;
    }

    this.curCoursePartId = id;
    this.curCoursePartIdSubject.next(id);
  }

  setCurActivityDone(done: boolean) {
    return this.courseDataService.markActivityAsDone(this.curActivityId, done);
  }

  getActivityIdForStaticPartIndex(index: number) {
    return index - CoursePageService.STATIC_COURSE_PARTS.length;
  }

  showResultForCurActivity() {
    this.showResultSubject.next();
  }

  getNavItems(): NavItem[] {
    const res = [null, null, null];

    if (!this.course) {
      return res;
    }

    const curActivities = CourseHelper.getCourseActivities(this.course);

    let activityIndex;
    if (this.curActivityId < 0) {
      activityIndex = this.curActivityId;
    } else {
      activityIndex = _.findIndex(curActivities, { id: this.curActivityId });
    }

    for (let offset = -1; offset < 2; offset++) {
      const index = activityIndex + offset;
      let activitiesSeq;
      if (index < 0) {
        activitiesSeq = CoursePageService.STATIC_COURSE_PARTS;
      } else {
        activitiesSeq = curActivities;
      }

      res[offset + 1] = _.nth(activitiesSeq, index);
    }

    return res;
  }

  private getCurActivityIndex(): CourseActivityIndex {
    if (this.curActivityId >= 0) {
      return CourseHelper.getActivityIndex(this.curActivityId, this.course);
    } else {
      const part = -1;
      const activity =
        CoursePageService.STATIC_COURSE_PARTS.length + this.curActivityId;
      return { part, activity };
    }
  }

  private setActivityByIndex(activityIndex: CourseActivityIndex) {
    const { part, activity } = activityIndex;
    let activityId;

    if (part < 0) {
      activityId = this.getActivityIdForStaticPartIndex(activity);
    } else {
      activityId = CourseHelper.getActivityByIndex(
        activityIndex,
        this.course
      )?.id;
    }

    this.setActivityId(activityId);
  }

  private setCourse(course: Course) {
    this.course = course;
    this.setActivityId(this.curActivityId, true); //for components to update data
  }

  private getActivities(partIndex: number) {
    if (partIndex >= 0) {
      let part = this.course?.parts[partIndex];
      return part ? part.activities : [];
    } else {
      return CoursePageService.STATIC_COURSE_PARTS;
    }
  }

  private updateCurActivity() {
    let curActivity = null;
    let curStaticPart = null;
    if (this.course) {
      if (this.curActivityId < 0) {
        curStaticPart = _.nth(
          CoursePageService.STATIC_COURSE_PARTS,
          this.curActivityId
        );
      } else if (this.course) {
        curActivity = CourseHelper.getActivity(this.curActivityId, this.course);

        if (!curActivity) {
          this.setActivityId(this.getActivityIdForStaticPartIndex(0));
          return;
        }
      }
    }

    this.curActivitySubject.next(curActivity);
    this.curStaticPartSubject.next(curStaticPart);
    this.setCoursePart();
  }
}
