import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CourseImageMaterial } from 'src/models/course-material/course-image-material';

@Component({
  selector: 'app-image-content',
  templateUrl: './image-content.component.html',
  styleUrls: ['./image-content.component.scss'],
})
export class ImageContentComponent implements OnInit {
  @Input() imageMaterial: CourseImageMaterial;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
