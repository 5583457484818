import { Component, Input, OnInit } from '@angular/core';
import { GradeDescription } from 'src/models/grade-description';
import { Points } from 'src/enums/points.enum';

@Component({
  selector: 'app-badge-label',
  templateUrl: './badge-label.component.html',
  styleUrls: ['./badge-label.component.scss'],
})
export class BadgeLabelComponent implements OnInit {
  readonly badgeDescription = {
    coursepoint: 'Kurspoäng',
    masterpoint: 'Mästarpoäng',
    orderpoint: 'Ordningspoäng',
  };

  @Input() grade: number;
  @Input() progress: number;
  @Input() isCriteria = false;
  @Input() marked = false;

  constructor() {}

  ngOnInit(): void {}
}
