<div class="pointsContainer">
  <table class="overview-table">
    <thead>
      <td></td>

      <th colspan="3" class="center">Poäng</th>
    </thead>
    <thead>
      <th>Delmoment</th>
      <th>Kurspoäng</th>
      <th>Mästarpoäng</th>
      <th>Ordningspoäng</th>
    </thead>
    <tbody *ngFor="let part of coursePointsData?.parts">
      <tr>
        <td>{{ part?.coursePartTitle }}</td>

        <td
          class="center"
          *ngIf="part?.coursePartPoints.course.available !== 0"
        >
          {{
            part?.coursePartPoints?.course?.earned +
              ' / ' +
              part?.coursePartPoints?.course?.available
          }}
        </td>
        <td
          class="center"
          *ngIf="part?.coursePartPoints.course.available === 0"
        ></td>
        <td
          class="center"
          *ngIf="part?.coursePartPoints.master.available !== 0"
        >
          {{
            part?.coursePartPoints?.master?.earned +
              ' / ' +
              part?.coursePartPoints?.master?.available
          }}
        </td>
        <td
          class="center"
          *ngIf="part?.coursePartPoints.master.available === 0"
        ></td>
        <td class="center" *ngIf="part?.coursePartPoints.order.available !== 0">
          {{
            part?.coursePartPoints?.order?.earned +
              ' / ' +
              part?.coursePartPoints?.order?.available
          }}
        </td>
        <td
          class="center"
          *ngIf="part?.coursePartPoints.order.available === 0"
        ></td>
      </tr>
    </tbody>
    <tfoot>
      <tr class="bold">
        <td>Summa</td>
        <td
          class="center"
          *ngIf="coursePointsData?.coursePoints.course.available !== 0"
        >
          <div class="cell center">
            {{
              coursePointsData?.coursePoints?.course?.earned +
                ' / ' +
                coursePointsData?.coursePoints?.course?.available
            }}
            <i
              class="bi bi-star-fill icon"
              [ngClass]="{
                bronze:
                  coursePointsData.coursePoints.course.earned /
                    coursePointsData.coursePoints.course.available >
                    0 &&
                  coursePointsData.coursePoints.course.earned /
                    coursePointsData.coursePoints.course.available <=
                    0.3,
                silver:
                  coursePointsData.coursePoints.course.earned /
                    coursePointsData.coursePoints.course.available >
                    0.3 &&
                  coursePointsData.coursePoints.course.earned /
                    coursePointsData.coursePoints.course.available <=
                    0.7,
                gold:
                  coursePointsData.coursePoints.course.earned /
                    coursePointsData.coursePoints.course.available >
                  0.7
              }"
            ></i>
          </div>
        </td>
        <td
          class="center"
          *ngIf="coursePointsData?.coursePoints.course.available === 0"
        ></td>
        <td
          class="center"
          *ngIf="coursePointsData?.coursePoints.master.available !== 0"
        >
          <div class="cell center">
            {{
              coursePointsData?.coursePoints?.master?.earned +
                ' / ' +
                coursePointsData?.coursePoints?.master?.available
            }}
            <i
              class="bi bi-shield-fill icon"
              [ngClass]="{
                bronze:
                  coursePointsData.coursePoints.master.earned /
                    coursePointsData.coursePoints.master.available >
                    0 &&
                  coursePointsData.coursePoints.master.earned /
                    coursePointsData.coursePoints.master.available <=
                    0.3,
                silver:
                  coursePointsData.coursePoints.master.earned /
                    coursePointsData.coursePoints.master.available >
                    0.3 &&
                  coursePointsData.coursePoints.master.earned /
                    coursePointsData.coursePoints.master.available <=
                    0.7,
                gold:
                  coursePointsData.coursePoints.master.earned /
                    coursePointsData.coursePoints.master.available >
                  0.7
              }"
            ></i>
          </div>
        </td>
        <td
          class="center"
          *ngIf="coursePointsData?.coursePoints.master.available === 0"
        ></td>
        <td
          class="center"
          *ngIf="coursePointsData?.coursePoints.order.available !== 0"
        >
          <div class="cell center">
            {{
              coursePointsData?.coursePoints?.order?.earned +
                ' / ' +
                coursePointsData?.coursePoints?.order?.available
            }}
            <i
              class="bi bi-puzzle-fill icon"
              [ngClass]="{
                bronze:
                  coursePointsData.coursePoints.order.earned /
                    coursePointsData.coursePoints.order.available >
                    0 &&
                  coursePointsData.coursePoints.order.earned /
                    coursePointsData.coursePoints.order.available <=
                    0.3,
                silver:
                  coursePointsData.coursePoints.order.earned /
                    coursePointsData.coursePoints.order.available >
                    0.3 &&
                  coursePointsData.coursePoints.order.earned /
                    coursePointsData.coursePoints.order.available <=
                    0.7,
                gold:
                  coursePointsData.coursePoints.order.earned /
                    coursePointsData.coursePoints.order.available >
                  0.7
              }"
            ></i>
          </div>
        </td>
        <td
          class="center"
          *ngIf="coursePointsData?.coursePoints.order.available === 0"
        ></td>
      </tr>
    </tfoot>
  </table>
</div>
