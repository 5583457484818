import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationsDataService } from 'src/app/services/notifications-data.service';
import { GeneralNotification } from 'src/models/notification/general-notification';

@UntilDestroy()
@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  notifications: GeneralNotification[];

  constructor(
    private notificationsService: NotificationsDataService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.notificationsService.$notifications
      .pipe(untilDestroyed(this))
      .subscribe((notifications) => (this.notifications = notifications));

    this.authService.$jwt.pipe(untilDestroyed(this)).subscribe((jwt) => {
      if (jwt && jwt.name) {
        this.notificationsService.getNotifications().subscribe();
      } else {
        this.notificationsService.clearLocalNotifications();
      }
    });
  }
}
