import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { CourseCollection } from 'src/models/course-collection';
import { CourseDataService } from 'src/app/services/course-data.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoursePageService } from 'src/app/services/course-page.service';
import { NotificationsDataService } from 'src/app/services/notifications-data.service';

@UntilDestroy()
@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
  active: number;
  registredGroups: number[] = [];
  confirmedGroups: Object = {};
  registredCollections: number[] = [];
  courseCollections: CourseCollection[];
  currentCourseId: number;
  currentUser: string;
  currentActivityId: number;
  confirmedGroup: boolean;

  constructor(
    private courseDataService: CourseDataService,
    private coursePageService: CoursePageService,
    private authService: AuthService,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private notificationService: NotificationsDataService
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      let collectionId = params.get('collection');
      if (collectionId !== null) {
        this.active = parseInt(params.get('collection'));
        this.coursePageService.setCurGroupCollectionId(this.active);
        this.onNavIdChange();
      }
    });
    this.courseDataService.$course
      .pipe(untilDestroyed(this))
      .subscribe((course) => {
        try {
          this.currentCourseId = course.id;
          this.getCourseGroupCollections(this.currentCourseId);
          this.checkRegistred(this.currentCourseId);
        } catch (error) {}
      });
    this.authService.$jwt.pipe(untilDestroyed(this)).subscribe((jwt) => {
      if (jwt && jwt.name) {
        this.currentUser = jwt.liuId;
      }
    });
  }

  public onNavIdChange() {
    this.notificationService.markGroupDeadlineRead(this.active).subscribe();
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { collection: this.active },
      queryParamsHandling: 'merge',
    });
  }

  private getCourseGroupCollections(courseId: number) {
    this.courseDataService
      .getCourseCollections(courseId)
      .subscribe((courseGroupCollections) => {
        this.courseCollections = courseGroupCollections;
        this.updateRegistredCollections();
        if (this.active === null || this.active === undefined) {
          this.active = this.courseCollections[0].id;
          this.coursePageService.setCurGroupCollectionId(this.active);
        }
      });
  }

  private addGroupMember(
    groupId: number,
    collectionId: number,
    name: string,
    email: string,
    liuId: string
  ) {
    let collection = _.find(this.courseCollections, { id: collectionId });
    let group = _.find(collection.groups, { id: groupId });
    group.users.push({ email: email, liuId: liuId, name: name });
    this.registredGroups.push(groupId);
    this.updateRegistredCollections();
  }

  private removeGroupMember(
    groupId: number,
    collectionId: number,
    name: string,
    email: string,
    liuId: string
  ) {
    let collection = _.find(this.courseCollections, { id: collectionId });
    let group = _.find(collection.groups, { id: groupId });
    _.remove(group.users, { liuId: liuId });
    _.pull(this.registredGroups, groupId);
    this.updateRegistredCollections();
  }

  public registredEvent(register) {
    if (register !== -1) {
      this.addGroupMember(
        register.groupId,
        register.collectionId,
        register.name,
        register.email,
        register.liuId
      );
    }
  }

  public unregistredEvent(register) {
    if (register.groupId !== -1) {
      this.removeGroupMember(
        register.groupId,
        register.collectionId,
        register.name,
        register.email,
        register.liuId
      );
    }
  }

  private checkRegistred(courseId: number) {
    this.courseDataService.checkRegister(courseId).subscribe((group) => {
      if (group !== null) {
        this.registredGroups = group;
      }
      this.updateRegistredCollections();
    });
  }

  private updateRegistredCollections() {
    this.registredCollections = [];
    if (this.courseCollections === undefined) {
      return;
    }
    for (let i = 0; i < this.courseCollections.length; i++) {
      let temp_id = this.courseCollections[i].id;
      for (let j = 0; j < this.courseCollections[i].groups.length; j++) {
        if (
          this.registredGroups.includes(this.courseCollections[i].groups[j].id)
        ) {
          this.registredCollections.push(temp_id);
        }
      }
    }
  }

  public acceptGroup(groupId: number) {
    this.courseDataService.acceptGroup(this.currentCourseId, groupId).subscribe(
      (res) => {
        this.toastService.showSuccess('Successfully accepted group');
        this.confirmedGroup = true;
      },
      (err) => {
        this.toastService.showDanger('Could not accept group');
      }
    );
  }

  public denyGroup(groupId: number) {
    this.courseDataService
      .declineGroup(this.currentCourseId, groupId)
      .subscribe(
        (res) => {
          this.toastService.showSuccess('Successfully declined group');
          this.confirmedGroups[groupId] = false;
          this.confirmedGroup = false;
        },
        (err) => {
          this.toastService.showDanger('Could not decline group');
        }
      );
  }
}
